/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import './index.css';


import { Context } from '../../../Context/AuthContext';




export default function Lista({...props}) {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [orcamentos, setOrcamentos] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);

    const loadedRef = useRef(false);

    const {Tab} = props;

     //#region Effects
     useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;

                if(Tab !== "" && Tab !== null && Tab !== undefined) {
                    if(Tab === "Historico") {
                        SelectTab(2);
                    }
                    LoadOrcamentos(2);
                } else {
                    SelectTab(1);
                    LoadOrcamentos(1);
                }
            }
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


     //#region Loaders
     const LoadOrcamentos = (tab) => {
        SelectTab(parseInt(tab));
        setPageLoading(true);
        setOrcamentos([]);

        Promise.resolve(new Request().Run("api/Home/Orcamentos?"+tab, "POST", {guid: user.guid, limit: 300, tab: tab}, user.token, 10*1000))
        .then((data) => {
            if(data.toString() !== "empty") {
                data.map((item, i) => {
                    item = Object.assign(item, {service: ServiceType(item.servico_id)})
                })

                /*for(let i=0; i<4;i++) {
                    data = data.concat(data);
                }*/
                
                setOrcamentos(data);
                setPageLoading(false);
            }
        }).catch(() => {
            setPageLoading(false);
        });
    }
    //#endregion Loaders


     //#region Functions
     const ServiceType = (service_id) => {
        if(service_id === 99) {
            service_id = 5;
        } else {
            service_id--;
        }

        let arr = [
            {name: "Manutenção", color: "orange", icon:"bi-wrench"},
            {name: "Conserto / Reparo", color: "green", icon:"bi-brush"},
            {name: "Instalação", color: "yellow", icon:"bi-battery-charging"},
            {name: "Limpeza e higienização", color: "blue", icon:"bi-droplet-fill"},
            {name: "Comprar", color: "red", icon:"bag-check-fill"},
            {name: "Serviço diferenciado", color: "gray", icon:"bi-person-bounding-box"}
        ];

        return arr[service_id];
    }


    const SelectTab = (id) => {
        for(let i=1; i<=2; i++) {
            if(i === id) {
                window.$('#tab'+i).attr("aria-expanded","true");
                window.$('#tab-'+i).addClass("show");
            } else {
                window.$('#tab'+i).attr("aria-expanded","false");
                window.$('#tab-'+i).removeClass("show");
            }
        }
    }
    //#endregion Functions


    return (
        <>
            <div className="OrcamentoLista card card-style">
                <div className="content mb-0">

                    <div className="tabs tabs-pill" id="tab-group-2">

                        <div className="tab-controls rounded-m p-1">
                            <a id="tab1" className="font-13 rounded-m" data-bs-toggle="collapse" href="#tab-1" aria-expanded="true" onClick={() => LoadOrcamentos(1)}>Todos</a>
                            <a id="tab2" className="font-13 rounded-m" data-bs-toggle="collapse" href="#tab-2" aria-expanded="false" onClick={() => LoadOrcamentos(2)}>Histórico</a>
                        </div>

                        <div className="mt-3"></div>
                        <div className="collapse show" id="tab-1" data-bs-parent="#tab-group-2">
                            <div className="list_group">
                            {
                                orcamentos.length > 0 ? (
                                    orcamentos.map((item, i) => (
                                        <LinkContainer key={'orc'+i} to={'/Orcamento/'+item.guid}>
                                        <a className="d-flex py-1">
                                            <div className="ListItem align-self-center">
                                                <span className={'icon rounded-s me-2 gradient-' + item.service.color + ' shadow-bg shadow-bg-s'}><i className={'bi ' + item.service.icon + ' color-white'}></i></span>
                                            </div>
                                            <div className="align-self-center ps-1">
                                                <h5 className="pt-1 mb-n1">{item.service.name} </h5>
                                                <p className="mb-0 font-11 opacity-50">{item.distance} km {item.cidade}-{item.uf} <span className="copyright-year"></span></p>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-red-dark">{item.valor_medio>0?Currency(item.valor_medio):(item.valor_medio===-1?'Bloqueado':'Não definido')}</h4>
                                                <p className="mb-0 font-11">Valor médio</p>
                                            </div>
                                        </a>
                                        </LinkContainer>
                                    ))
                                ) : (<>
                                    <a className="d-flex py-1">
                                        <div className="ListItem align-self-center">
                                            <span className="icon rounded-s me-2 gradient-gray shadow-bg shadow-bg-s"><i className="bi bi-clock-history color-white"></i></span>
                                        </div>
                                        <div className="ListItem align-self-center">
                                            <h5 className="pt-1 mb-n1">Nenhum orçamento encontrado </h5>
                                            
                                        </div>
                                    
                                    </a>
                                </>)
                            }
                            </div>
                        </div>

                        <div className="collapse" id="tab-2" data-bs-parent="#tab-group-2">
                            <div className="list_group">
                            {
                                orcamentos.length > 0 ? (
                                    orcamentos.map((item, i) => (
                                        <LinkContainer key={'orc'+i} to={'/Orcamento/'+item.guid}>
                                        <a className="d-flex py-1">
                                            <div className="ListItem align-self-center">
                                                <span className={'icon rounded-s me-2 gradient-' + item.service.color + ' shadow-bg shadow-bg-s'}><i className={'bi ' + item.service.icon + ' color-white'}></i></span>
                                            </div>
                                            <div className="align-self-center ps-1">
                                                <h5 className="pt-1 mb-n1">{item.service.name} </h5>
                                                <p className="mb-0 font-11 opacity-50">{item.distance} km {item.cidade}-{item.uf} <span className="copyright-year"></span></p>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                                <h4 className="pt-1 mb-n1 color-red-dark">{item.valor_medio>0?Currency(item.valor_medio):'Não definido'}</h4>
                                                <p className="mb-0 font-11">Valor médio</p>
                                            </div>
                                        </a>
                                        </LinkContainer>
                                    ))
                                ) : (<>
                                    <a className="d-flex py-1">
                                        <div className="ListItem align-self-center">
                                            <span className="icon rounded-s me-2 gradient-gray shadow-bg shadow-bg-s"><i className="bi bi-clock-history color-white"></i></span>
                                        </div>
                                        <div className="ListItem align-self-center">
                                            <h5 className="pt-1 mb-n1">Nenhum orçamento encontrado </h5>
                                            
                                        </div>
                                    
                                    </a>
                                </>)
                            }
                            </div>
                        </div>

                   

                    </div>

                </div>
            </div>
        </>
    )
}