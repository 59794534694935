/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { LinkContainer } from 'react-router-bootstrap';
import { Cache } from '../../scripts/Cache';
import ReactGA from 'react-ga';
import './index.css';


import { Context } from '../../Context/AuthContext';


export default function Login() {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [rememberPwd, setRememberPwd] = useState(false);
    const [state, setState] = useState({
        login: "",
        password: ""
    });

    const rememberRef = useRef(false);


     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        const _userpwd = Cache.JGet("userpwd");
        //console.log(_userpwd);

        try {
            if(_userpwd.login !== "") {
                setState({ login: _userpwd.login, password: _userpwd.password });
                rememberRef.current = true;
                setRememberPwd(true);
            }
        } catch(err) { }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Handlers
    const _HandlerChange = (event) => {
        const { id, value } = event.target;
        const _state = Object.assign({}, state);

        if (event.target.type === "checkbox") {
            //console.log(event.target.checked);
            rememberRef.current = event.target.checked;
            setRememberPwd(event.target.checked);
        } else {
            _state[id] = value;
        }

        setState(_state);
    }


    const _handleLogin = (event) => {
        event.preventDefault();
        const _state = Object.assign({}, state);
        const _remember = rememberRef.current; //Object.assign({}, rememberPwd);
        const _userpwd = Cache.JGet("userpwd");//localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password });

        if(_remember){
            //console.log(1, _remember);
            Cache.JSet("userpwd", { login: _state.login, password: _state.password });
        } else {
            //console.log(2, _remember);
            Cache.JSet("userpwd", { login: "", password: "" });
        }

        handleLogin(state);
    };
    //#endregion Handlers


    return (
    <>
        <div id="page" className="Login">
            <div className="page-content my-0 py-0">
                <div className="card bg-5 card-fixed">
                    <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
                        <center>
                            <img src="images/logo.png" alt="" />
                        </center>
               
                        <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                            <i className="bi bi-person-circle font-18"></i>
                            <input type="text" className="form-control rounded-xs" id="login" placeholder="E-mail" value={state.login} onChange={_HandlerChange} />
                        </div>
                        <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                            <i className="bi bi-asterisk font-18"></i>
                            <input type="password" className="form-control rounded-xs" id="password" placeholder="Senha" value={state.password} onChange={_HandlerChange} />
                        </div>
                        <div className="_form-check _form-check-custom">
                            <label className="form-check-label font-14" for="remember"><input className="form-check-input" type="checkbox" name="type" id="remember" onClick={_HandlerChange} checked={rememberPwd} />
                           &nbsp; Lembrar Senha</label>
                        </div>
                        <a className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4" onClick={_handleLogin}>ENTRAR</a>
                        <div className="row">
                            <div className="col-6 text-start">
                                <LinkContainer to="/Login/Forget">
                                    <a className="font-14 color-theme opacity-40 pt-4 d-block">Recuperar senha</a>
                                </LinkContainer>
                            </div>
                            <div className="col-6 text-end">
                                <LinkContainer to="/Login/Cadastro">
                                    <a className="font-14 color-theme opacity-40 pt-4 d-block">Cadastro</a>
                                </LinkContainer>
                            </div>
                        </div>
                    </div>
                    <div className="card-overlay rounded-0 m-0 bg-black opacity-40"></div>
                </div>
            </div>
        </div>
    </>
    )
}