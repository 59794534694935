import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useParams } from 'react-router-dom';
import './index.css';


import Menu from '../Home/Menu'
import Sidebar from '../Sidebar';
import Lista from './Lista';
import MenuTitle from '../MenuTitle';


export default function Orcamentos() {
 
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.getElementById("page").scrollIntoView({behavior: 'smooth'});
    }, []);

    let { Tab } = useParams();

    return (
        <>
        <div id="page">
            <div className="page-content footer-clear">
                <MenuTitle title="Orçamentos" backTo="/Home" />
                <Lista Tab={Tab}/>
            </div>
            <br />
            <Menu />
            <Sidebar />
        </div>
        </>
    )
}