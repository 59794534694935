/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './index.css';

export default function Cards() {

    

    return (
        <div className="splide_top">
            <Splide options={ {rewind: false, type: 'loop', gap : '10px', autoplay: true } }  aria-label="My Favorite Images">
                <SplideSlide>
                    <div className="">
                        <img className="card card-style m-0 shadow-card shadow-card-m" src="/images/pictures/1.png" alt="" />
                        <div className="card-top p-3">
                            <a href="https://www.rededac.com.br" target="_blank" className="icon icon-xxs bg-white color-black float-end "><i className="bi bi-three-dots font-18"></i></a>
                        </div>
                    </div>
                </SplideSlide>

                <SplideSlide>
                    <div className="">
                        <img className="card card-style m-0 shadow-card shadow-card-m" src="/images/pictures/2.png" alt="" />
                        <div className="card-top p-3">
                            <a href="https://www.conectarplay.com" target="_blank" className="icon icon-xxs bg-white color-black float-end "><i className="bi bi-three-dots font-18"></i></a>
                        </div>
                    </div>
                </SplideSlide>

                <SplideSlide>
                    <div className="">
                        <img className="card card-style m-0 shadow-card shadow-card-m" src="/images/pictures/3.png" alt="" />
                        <div className="card-top p-3">
                            <a href="" className="icon icon-xxs bg-white color-black float-end "><i className="bi bi-three-dots font-18"></i></a>
                        </div>
                    </div>
                </SplideSlide>

                <SplideSlide>
                    <div className="">
                        <img className="card card-style m-0 shadow-card shadow-card-m" src="/images/pictures/1.png" alt="" />
                        <div className="card-top p-3">
                            <a href="https://www.rededac.com.br" target="_blank"  className="icon icon-xxs bg-white color-black float-end "><i className="bi bi-three-dots font-18"></i></a>
                        </div>
                    </div>
                </SplideSlide>

                <SplideSlide>
                    <div className="">
                        <img className="card card-style m-0 shadow-card shadow-card-m" src="/images/pictures/2.png" alt="" />
                        <div className="card-top p-3">
                            <a href="https://www.conectarplay.com" target="_blank" className="icon icon-xxs bg-white color-black float-end "><i className="bi bi-three-dots font-18"></i></a>
                        </div>
                    </div>
                </SplideSlide>


                <SplideSlide>
                    <div className="">
                        <img className="card card-style m-0 shadow-card shadow-card-m" src="/images/pictures/3.png" alt="" />
                        <div className="card-top p-3">
                            <a href="#" className="icon icon-xxs bg-white color-black float-end "><i className="bi bi-three-dots font-18"></i></a>
                        </div>
                    </div>
                </SplideSlide>
            </Splide>
        </div>
    )
}