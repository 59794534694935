/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Request } from '../../../scripts/Request';
import StringMask from 'string-mask';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';

export default function Forget() {
    const [state, setState] = useState(0);
    const [pageLoading, setPageLoading] = useState(false);
    const [frm, setFrm] = useState({ email: "", new_pwd: "", repeat_pwd: "", code:"", token: "" });
    const [pwdState, setPwdState] = useState({message: "", color:"#FFFFFF"});


    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
     //#endregion Effects


    //#region Handlers
    const _HandlerChange = (e) => {
        const { id, value } = e.target;

        if (id === "celular") {
            let result1 = value.replace(/[^\d]+/g, '')
            let formatter = new StringMask("(99) 99999-9999");
            let result = formatter.apply(result1);
            setFrm(prevState => ({
                ...prevState,
                [id]: result
            }))
        } else  {
            setFrm(prevState => ({
                ...prevState,
                [id]: value
            }));

            if (id === "new_pwd") StrengthChecker(value);
        }
    }

    const _HandlerSend = async(e) => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Recovery/SendCode", "POST", frm, ''))
            .then((data) => {
                setPageLoading(false);
                if (data.toString() === "loaded") {
                    setState(1);
                } else if (data.toString() === "error") {
                    setState(0);
                    window.swal("Alerta", "Ocorreu um erro na sua solicitação!", "error")
                }
            });
    }


    const _HandlerCode = async (e) => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Recovery/ValidateCode", "POST", frm, ''))
            .then((data) => {
                setPageLoading(false);
                if (data.toString() === "error") {
                    setState(0);
                    window.swal("Alerta", "Ocorreu um erro na sua solicitação!", "error")
                } else {
                    let _frm = Object.assign({}, frm);
                    _frm.token = data;
                    setFrm(_frm);
                    setState(2);
                }
            });
    }


    const _HandlerSave = async (e) => {
        //setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Recovery/ChangePwd", "POST", frm, ''))
            .then((data) => {
                //setPageLoading(false);
                if (data.toString() === "error") {
                    setState(0);
                    window.swal("Alerta", "Ocorreu um erro na sua solicitação!", "error");
                } else {
                    window.swal("Sucesso", "Sua nova senha foi salva!", "success");
                    navigate("/login");
                }
            });
    }
    //#region Handlers


    //#region Functions
    const StrengthChecker = (pass) => {
        var score = scorePassword(pass);
        //console.log(score, pass);

        if (score >= 75) {
            setPwdState({ message: "Senha Forte", color: "#44FF66" });
        } else if (score > 50) {
            setPwdState({ message: "Senha Acentável", color: "#0000FF" });
        } else if (score >= 25) {
            setPwdState({ message: "Senha Fraca", color: "#FF0000" });
        } else if (score <= 5) {
            setPwdState({ message: "Senha Ruim", color: "#FF0000" });
        }
    }


    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
    //#endregion Functions

    return (
        <>
        <div id="page" className="Login">
            <div className="page-content my-0 py-0">
                <div className="card bg-5 card-fixed">
              
                    { state === 0 ? (
                    <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
                        <center>
                        <h1 className="font-30 font-800 mb-0">Recuperar senha</h1>
                        <p>Entre com seu e-mail para obter o código</p>
                        </center>
               
                        <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                            <i className="bi bi-at font-20"></i>
                            <input type="text" className="form-control rounded-xs" id="email" placeholder="E-mail" value={frm.email} onChange={_HandlerChange} autoComplete="off"/>
                            <span></span>
                        </div>

                        <a className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"  disabled={!(frm.email.indexOf("@") > -1 && frm.email.length > 5)} onClick={_HandlerSend}>ENVIAR CÓDIGO DE RECUPERAÇÃO</a>
                        <div className="row">
                            <div className="col-6 text-start">
                                <LinkContainer to="/Login">
                                    <a className="font-14 color-theme opacity-40 pt-4 d-block">Fazer login</a>
                                </LinkContainer>
                            </div>
                            <div className="col-6 text-end">
                                <LinkContainer to="/Login/Cadastro">
                                    <a className="font-14 color-theme opacity-40 pt-4 d-block">Cadastro</a>
                                </LinkContainer>
                            </div>
                        </div>
                    </div>
                    ) : (
                        state === 1 ? (
                            <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
                                <center>
                                <h1 className="font-30 font-800 mb-0">Validar código</h1>
                                <p>Digite seu código de recuperação para continuar</p>
                                </center>
                    
                                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                                    <i className="bi bi-lock font-20"></i>
                                    <input type="text" className="form-control rounded-xs" id="code" value={frm.code} onChange={_HandlerChange}  placeholder="Código de recuperação" />
                                    <span></span>
                                </div>

                                <a className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4" disabled={(frm.code.length < 6)} onClick={_HandlerCode}>VALIDAR CÓDIGO</a>
                                <div className="row">
                                    <div className="col-6 text-start">
                                        <LinkContainer to="/Login">
                                            <a className="font-14 color-theme opacity-40 pt-4 d-block">Fazer login</a>
                                        </LinkContainer>
                                    </div>
                                    <div className="col-6 text-end">
                                        <LinkContainer to="/Login/Cadastro">
                                            <a className="font-14 color-theme opacity-40 pt-4 d-block">Cadastro</a>
                                        </LinkContainer>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
                                <center>
                                <h1 className="font-30 font-800 mb-0">Nova senha</h1>
                                <p>Crie uma nova senha para acesso</p>
                                </center>
                    
                                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                                    <i className="bi bi-lock font-20"></i>
                                    <input type="password" className="form-control rounded-xs" id="new_pwd" placeholder="Nova senha" value={frm.new_pwd} onChange={_HandlerChange} />
                                    <span style={{color:pwdState.color, fontSize:'12px'}}>{pwdState.message}</span>
                                </div>

                                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                                    <i className="bi bi-lock font-20"></i>
                                    <input type="password" className="form-control rounded-xs" id="repeat_pwd" placeholder="Repetir senha" value={frm.repeat_pwd} onChange={_HandlerChange} />
                                    <span></span>
                                    <h4 className={(frm.new_pwd.length >= 6 && frm.repeat_pwd.length >= 1 && frm.new_pwd !== frm.repeat_pwd) ? '' :'hide'} style={{color:'red', fontSize:'14px'}}> <center>As senhas não são iguais!</center> </h4>
                                </div>

                                <a  className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4" disabled={(frm.new_pwd !== frm.repeat_pwd && frm.new_pwd.length < 6)} onClick={_HandlerSave}>SALVAR SENHA</a>
                                <div className="row">
                                    <div className="col-6 text-start">
                                        <LinkContainer to="/Login">
                                            <a className="font-14 color-theme opacity-40 pt-4 d-block">Fazer login</a>
                                        </LinkContainer>
                                    </div>
                                    <div className="col-6 text-end">
                                        <LinkContainer to="/Login/Cadastro">
                                            <a className="font-14 color-theme opacity-40 pt-4 d-block">Cadastro</a>
                                        </LinkContainer>
                                    </div>
                                </div>
                            </div>
                            )
                    )}
                    <div className="card-overlay rounded-0 m-0 bg-black opacity-40"></div>
                </div>
            </div>
        </div>
    </>
    )
}