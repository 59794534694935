import { useState, useEffect, useRef } from 'react';
import { Request } from '../../scripts/Request';
import { useNavigate } from 'react-router-dom';



export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ loja_id: "", guid: "", nome: "", token: "", lastAccess: "", nivel: "", code: "", validade: "" });
    const authRef = useRef(false);
    const tokenRef = useRef("");
    const navigate = useNavigate();



    useEffect(() => {
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;
            tokenRef.current = _u.token;
        }
        setLoading(false);
    }, []);


    async function UserValidate() {
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;;
            tokenRef.current = _u.token;
        }
        setLoading(false);

        return JSON.parse(_user);
    }

    async function handleLogin(form) {
        setLoading(true);
        window.w_loadObj("body");

        try {
            //eslint-disable-next-line no-undef
            let tokenObj = invokeCSharpAction(JSON.stringify({cmd: "get_token", data: ""}));
            if(tokenObj) {
                form = Object.assign(form, {token: tokenObj.toString()});
            }
        } catch(e) { }

        const user = await Promise.resolve(new Request().Run("api/Auth/Login", "Post", form, ""))
            .then((data) => {
                return data;
            });

        window.w_unloadAll();
        setLoading(false);

        if (user.loja_id === 0) {
            window.swal("Acesso não autorizado", "Senha Incorreta", "warning");
        } else {
            localStorage.setItem('user', JSON.stringify(user));
            setAuthenticated(true);
            authRef.current = true;
            setUser(user);
            tokenRef.current = user.token;

            //await UpdateToken(tokenRef.current);

            navigate('/Home');
        }
    }


    async function handleLogout(reload) {
        setAuthenticated(false);
        authRef.current = false;
        setUser({ loja_id: "", guid: "", nome: "", token: "", lastAccess: "", code: "", validade: "" });
        localStorage.removeItem('user');
        if(!reload) navigate('/');
    }


    async function UpdateToken(user_token) {
        try {
            // eslint-disable-next-line no-undef
            let tokenObj = invokeCSharpAction(JSON.stringify({cmd: "get_token", data: ""}));

            Promise.resolve(new Request().Run("api/Cadastro/UpdateToken", "POST", {guid: user.guid, token: tokenObj.toString()}, user_token, 0))
            .then((data) => {
                
            }).catch(() => {
                
            });
        }
        catch (err) {
            
        }
    }

    return { authenticated, loading, handleLogin, handleLogout, user, UserValidate };
}