/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import './index.css';


import { Context } from '../../../Context/AuthContext';




export default function Activity() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [orcamentos, setOrcamentos] = useState([]);

    const loadedRef = useRef(false);


    
    //#region Effects
    useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadOrcamentos();
            }
        }
    }, [user]);
    //#endregion Effects


    //#region Loaders
    const LoadOrcamentos = () => {
        Promise.resolve(new Request().Run("api/Home/Orcamentos", "POST", {guid: user.guid, limit: 5, tab: 1}, user.token, 10*1000))
        .then((data) => {
            if(data.toString() !== "empty") {
                data.map((item, i) => {
                    item = Object.assign(item, {service: ServiceType(item.servico_id)})
                })
                console.log(data)
                setOrcamentos(data);
            }
        }).catch(() => {
            
        });
    }
    //#endregion Loaders


    //#region Functions
    const ServiceType = (service_id) => {
        if(service_id === 99) {
            service_id = 5;
        } else {
            service_id--;
        }

        let arr = [
            {name: "Manutenção", color: "orange", icon:"bi-wrench"},
            {name: "Conserto / Reparo", color: "green", icon:"bi-brush"},
            {name: "Instalação", color: "yellow", icon:"bi-battery-charging"},
            {name: "Limpeza e higienização", color: "blue", icon:"bi-droplet-fill"},
            {name: "Comprar", color: "red", icon:"bag-check-fill"},
            {name: "Serviço diferenciado", color: "gray", icon:"bi-person-bounding-box"}
        ];

        return arr[service_id];
    }
    //#endregion Functions


    return (
        <>
        <div className="content my-0 mt-n2 px-1">
            <div className="d-flex">
                <div className="align-self-center">
                    <h3 className="font-16 mb-2">Orçamentos Recentes</h3>
                </div>
                <div className="align-self-center ms-auto">
                    <LinkContainer to="/Orcamentos">
                    <a className="font-12 pt-1">Ver Todos</a>
                    </LinkContainer>
                </div>
            </div>
        </div>

        <div className="card card-style">
            <div className="content">

                {
                    orcamentos.length > 0 ? (
                        orcamentos.map((item, i) => (
                            <LinkContainer key={'orc'+i} to={'/Orcamento/'+item.guid}>
                            <a  className="d-flex py-1">
                                <div className="align-self-center">
                                    <span className={'icon rounded-s me-2 gradient-' + item.service.color + ' shadow-bg shadow-bg-s'}><i className={'bi ' + item.service.icon + ' color-white'}></i></span>
                                </div>
                                <div className="align-self-center ps-1">
                                    <h5 className="pt-1 mb-n1">{item.service.name} </h5>
                                    <p className="mb-0 font-11 opacity-50">{item.distance} km {item.cidade}-{item.uf} <span className="copyright-year"></span></p>
                                </div>
                                <div className="align-self-center ms-auto text-end">
                                    <h4 className="pt-1 mb-n1 color-red-dark">{item.valor_medio>0?Currency(item.valor_medio):(item.valor_medio===-1?'Bloqueado':'Não definido')}</h4>
                                    <p className="mb-0 font-11">Valor médio</p>
                                </div>
                            </a>
                            </LinkContainer>
                        ))
                    ) : (<>
                        <a className="d-flex py-1">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-gray shadow-bg shadow-bg-s"><i className="bi bi-clock-history color-white"></i></span>
                            </div>
                            <div className="align-self-center">
                                <h5 className="pt-1 mb-n1">Nenhum orçamento encontrado </h5>
                                
                            </div>
                          
                        </a>
                    </>)
                }



                {/* 
                <a href="page-activity.html" className="d-flex py-1">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-orange shadow-bg shadow-bg-s"><i className="bi bi-wrench color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">Manutenção </h5>
                        <p className="mb-0 font-11 opacity-50">3,8 km Taubate-SP <span className="copyright-year"></span></p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                        <h4 className="pt-1 mb-n1 color-red-dark">R$ 150,00</h4>
                        <p className="mb-0 font-11">Valor médio</p>
                    </div>
                </a>
                <div className="divider my-2 opacity-50"></div>
                <a href="page-activity.html" className="d-flex py-1">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-s"><i className="bi bi-brush color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">Conserto / Reparo</h5>
                        <p className="mb-0 font-11 opacity-50">10 Maio <span className="copyright-year"></span></p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                        <h4 className="pt-1 mb-n1 color-blue-dark">R$ 150,00</h4>
                        <p className="mb-0 font-11">Valor médio</p>
                    </div>
                </a>
                <div className="divider my-2 opacity-50"></div>
                <a href="page-activity.html" className="d-flex py-1">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-blue shadow-bg shadow-bg-s"><i className="bi bi-droplet-fill color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">Limpeze e higienização</h5>
                        <p className="mb-0 font-11 opacity-50">10 Maio <span className="copyright-year"></span></p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                        <h4 className="pt-1 mb-n1 color-blue-dark">R$ 150,00</h4>
                        <p className="mb-0 font-11">Valor médio</p>
                    </div>
                </a>
                <div className="divider my-2 opacity-50"></div>
                <a href="page-activity.html" className="d-flex py-1">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-gray shadow-bg shadow-bg-s"><i className="bi bi-person-bounding-box color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">Serviço diferenciado</h5>
                        <p className="mb-0 font-11 opacity-50">10 Maio <span className="copyright-year"></span></p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                        <h4 className="pt-1 mb-n1 color-blue-dark">R$ 150,00</h4>
                        <p className="mb-0 font-11">Valor médio</p>
                    </div>
                </a>
                <div className="divider my-2 opacity-50"></div>
                <a href="page-activity.html" className="d-flex py-1">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-yellow shadow-bg shadow-bg-s"><i className="bi bi-battery-charging color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">Instalação</h5>
                        <p className="mb-0 font-11 opacity-50">8 Maio <span className="copyright-year"></span></p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                        <h4 className="pt-1 mb-n1 color-green-dark">R$ 150,00</h4>
                        <p className="mb-0 font-11">Valor médio</p>
                    </div>
                </a>*/}
            </div>
        </div>
    </>
    )
}