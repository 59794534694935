/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { HubObjects } from '../../scripts/HubObjects';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './index.css';


import { Context } from '../../Context/AuthContext';


export default function Sidebar() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [validateMessage, setValidateMessage] = useState({ icon: "bi-clock", message: "Carregando..."});

    const _sideopened = useRef(false);
    const _blockDoubleEvent = useRef(false);

    const navigate = useNavigate();

     //#region Effects
     useEffect(() => {
        RegisterHub();
        Validade();

    }, []);
    //#endregion Effects


    //#region Handlers
    const _HandleExit = () => {
        try {
            // eslint-disable-next-line no-undef
            invokeCSharpAction(JSON.stringify({cmd: "exit", data: ""}));
        }
        catch (err) {
            
        }
        navigate('/');
    }
    //#endregion Handlers


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("TOOGLE_SIDEBAR", (frombody) => {
            if(!_blockDoubleEvent.current) {
                _blockDoubleEvent.current = true;
                if(frombody) {
                    if(_sideopened.current) {
                        _sideopened.current = false;
                        window.$("#menu-sidebar").removeClass("menu_in");
                        window.$("#menu-sidebar").addClass("menu_out");
                    }
                } else {
                    if(!_sideopened.current) {
                        _sideopened.current = true;
                        window.$("#menu-sidebar").removeClass("menu_out");
                        window.$("#menu-sidebar").addClass("menu_in");
                    } else {
                        _sideopened.current = false;
                        window.$("#menu-sidebar").removeClass("menu_in");
                        window.$("#menu-sidebar").addClass("menu_out");
                    }
                }
                setTimeout(()=>{_blockDoubleEvent.current = false;}, 100);
            }
        });
    }



    //#endregion HubObjects


    //#region Functions
    const Validade = () => {
        if(user.validade !== "") {
            let a = moment(user.validade);
            let b = moment(new Date());
            let _diff = a.diff(b, 'days');

            if(_diff < 0) {
                setValidateMessage({icon: "bi-exclamation-triangle", message: "Plano expirado a " + Math.abs(_diff) + " dias"});
            } else if(_diff === 0) {
                setValidateMessage({icon: "bi-exclamation-triangle", message: "Plano encerra em Hoje"});
            } else if(_diff <= 7) {
                setValidateMessage({icon: "bi-exclamation-triangle", message: "Plano encerra em " + _diff + " dias"});
            } else {
                setValidateMessage({icon: "bi-check2-circle", message: "Validade até " + a.format('DD/MM/YYYY')});
            }
        }
    }


    const FirstName = (name) => {
        if(name.indexOf(" ") > -1) {
            name = name.split(" ")[0];
        }
        return name;
    }
    //#endregion Functions
    return (
        <div className="Sidebar">
            <div id="menu-sidebar" className="collapse collapse-horizontal rounded-m">
                <div className="menu-size">

                    <div className="pt-3">
                        <div className="page-title sidebar-title d-flex">
                            <div className="align-self-center me-auto">
                                <p className="color-highlight">{validateMessage.message}</p>
                                <h2>{FirstName(user.nome)}</h2>
                            </div>
                            <div className="align-self-center ms-auto">
                                <a href="#" data-bs-toggle="dropdown" className="icon gradient-blue shadow-bg shadow-bg-s rounded-m">
                                    <img src="/images/pictures/25s-1.jpg" width="45" className="rounded-m" alt="img" />
                                </a>
                            </div>
                        </div>
                        <div className="divider divider-margins mb-3 opacity-50"></div>

                        <div className="list-group list-custom list-menu-large">
                            <LinkContainer to="/Perfil">
                            <a href="#" id="nav-welcome" className="list-group-item">
                                <i className="bi bg-green-dark shadow-bg shadow-bg-xs bi-person-lines-fill"></i>
                                <div>Meu cadastro</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                            </LinkContainer>

                            <LinkContainer to="/Planos">
                            <a href="#" id="nav-welcome" className="list-group-item">
                                <i className="bi bg-green-dark shadow-bg shadow-bg-xs bi-tags"></i>
                                <div>Planos</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                            </LinkContainer>

                            <LinkContainer to="/Sobre">
                            <a href="#" id="nav-welcome" className="list-group-item">
                                <i className="bi bg-blue-dark shadow-bg shadow-bg-xs bi-app-indicator"></i>
                                <div>Sobre a i2play</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                            </LinkContainer>

                            <LinkContainer to="/Privacidade">
                            <a href="#" id="nav-welcome" className="list-group-item">
                                <i className="bi bg-blue-dark shadow-bg shadow-bg-xs bi-card-text"></i>
                                <div>Uso e privacidade</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                            </LinkContainer>

                            <LinkContainer to="/Como">
                            <a href="#" id="nav-welcome" className="list-group-item">
                                <i className="bi bg-gray-dark shadow-bg shadow-bg-xs bi-question-circle"></i>
                                <div>Como funciona</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                            </LinkContainer>

                            {/*<LinkContainer to="/Contato">
                            <a href="#" id="nav-welcome" className="list-group-item">
                                <i className="bi bg-gray-dark shadow-bg shadow-bg-xs bi-envelope"></i>
                                <div>Contato</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                            </LinkContainer>*/}
                        
                        </div>
                        <div className="divider divider-margins mb-3 opacity-50"></div>

              
                        <div className="list-group list-custom list-menu-small">
                           
                            <a href="#" className="list-group-item" onClick={_HandleExit}>
                                <i className="bi bi-power opacity-40 font-16"></i>
                                <div>Sair</div>
                                <i className="bi bi-chevron-right"></i>
                            </a>
                        </div>
                        <div className="divider divider-margins opacity-50"></div>

    
                    </div>
                </div>
            </div>
        </div>
    )
}