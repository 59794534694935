import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';


import Menu from '../Home/Menu'
import Sidebar from '../Sidebar';
import Cadastro from './Cadastro';
import MenuTitle from '../MenuTitle';


export default function Perfil() {
 

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.getElementById("page").scrollIntoView({behavior: 'smooth'});
    }, []);


    return (
        <>
        <div id="page">
            <div className="page-content footer-clear">
                <MenuTitle title="Perfil" backTo="/Home" />
                <Cadastro />
            </div>
            <br />
            <Menu />
            <Sidebar />
        </div>
        </>
    )
}