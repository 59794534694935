/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { LinkContainer } from 'react-router-bootstrap';
import { Cache } from '../../scripts/Cache';
import ReactGA from 'react-ga';
import './index.css';

import { Context } from '../../Context/AuthContext';

import Menu from './Menu'
import Top from './Top';
import Cards from './Cards';
import MenuInterno from './MenuInterno';
import Activity from './Activity';
import ActivityNotify from './ActivityNotify';
import GalleryNames from './GalleryNames';
import Sidebar from '../Sidebar';


export default function Home() {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);

   


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.getElementById("page").scrollIntoView({behavior: 'smooth'});
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
    }, [pageLoading]);
    //#endregion Effects



    return (
        <>
        <div id="page">
            <div className="page-content footer-clear">
                <Top />
                <Cards />
                <MenuInterno />
                <Activity />
                <ActivityNotify />
                <GalleryNames />
            </div>
            <br />
            <Menu />
            <Sidebar />
        </div>
        </>
    )
}