import React from 'react';
import RouteManager from './RouteManager';
import { BrowserRouter as Router} from 'react-router-dom';
import { HubObjects } from './scripts/HubObjects';
import './App.css';

import { AuthProvider } from './Context/AuthContext';



function App() {

  const HandleOpenSidebar = () => {
    HubObjects.Exec("TOOGLE_SIDEBAR", true);
  }

  return (
    
    <div className="App" onClick={HandleOpenSidebar}>
      <Router>
          <AuthProvider>
            <RouteManager />
          </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
