import React, { useContext, useState, useEffect, useRef } from 'react';
import './index.css';


import { Context } from '../../../Context/AuthContext';


export default function Content() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);


    return (
        <div id="page" className="Sobre">

            <div className="page-content footer-clear card card-style">
                <h2>Descrição da plataforma</h2>
                <br />
                <p>O projeto <b>i2Play</b> é a ferramenta para unir empresas e prestadores de serviços a seus clientes finais do ramo de refrigeração, climatização, ar-condicionado e linha branca.</p>
                <p>A ferramenta consiste em qualquer cliente pode solicitar de forma gratuita, rápida e sem burocracia através de seu celular, tablet ou computador via internet um orçamento de serviço para todos os prestadores de serviços e empresas que estiverem cadastrados na plataforma <b>i2Play</b> na cidade selecionada ou num raio de 50 km.</p>
                <p>O consumidor final acessa o link, preenche os dados e envia o orçamento que precisa, as empresas e profissionais da área de atuação/atendimento (50km) receberão no celular via APP <b>i2Play</b> a notificação do orçamento solicitado.</p>
                <p>Para visualizar os orçamentos é preciso ser assinante de um plano que pode ser contratado de forma rápida e imediata dentro do próprio app <b>i2Play</b>. Planos a partir de R$ 11,90 por mês.</p>
                <p>A assinatura poderá ser realizada a qualquer momento e o desbloqueio é automático pela própria plataforma que identifica o pagamento e libera a função.</p>
                <p>O parceiro <b>i2Play</b> enviará o seu orçamento automaticamente para whatsapp do cliente que solicitou e seguirá a conversa via whatsapp diretamente com seu novo futuro cliente.</p>
                <p>A <b>i2Play</b> não se responsabiliza pela aprovação da proposta/orçamento enviado, ou execução do serviço, pois é apenas um canal de comunicação, ligando o cliente ao prestador de serviço.</p>
            </div>
        </div>
    )
}