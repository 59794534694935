import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { Context } from './Context/AuthContext';
import { GetServer } from './scripts/Loadbalancer';
import Login from './components/Login';

import Layout from './Layout';
import Cadastro from './components/Login/Cadastro';
import Forget from './components/Login/Forget';
import Home from './components/Home';
import Orcamentos from './components/Orcamentos';
import Planos from './components/Planos';
import MeusPlanos from './components/MeusPlanos';
import Perfil from './components/Perfil';
import Orcamento from './components/Orcamento';
import Avaliacoes from './components/Avaliacoes';
import Pagamento from './components/Pagamento';
import Sobre from './components/Sobre';
import Privacidade from './components/Privacidade';
import Como from './components/Como';
import Compartilhar from './components/Compartilhar';


export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const routeRef = useRef("");


    useEffect(() => {
      GetServer();
    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
        } else {
            return children;
        }

      };

    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Login/Cadastro" element={<Cadastro />} />
                <Route exact path="/Login/Forget" element={<Forget />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route exact path="/Orcamentos" element={<ProtectedRoute><Orcamentos /></ProtectedRoute>} />
                <Route exact path="/Orcamentos/:Tab" element={<ProtectedRoute><Orcamentos /></ProtectedRoute>} />
                <Route exact path="/Planos" element={<ProtectedRoute><Planos /></ProtectedRoute>} />
                <Route exact path="/Planos/:PlanoId" element={<ProtectedRoute><Planos /></ProtectedRoute>} />
                <Route exact path="/MeusPlanos" element={<ProtectedRoute><MeusPlanos /></ProtectedRoute>} />
                <Route exact path="/Perfil" element={<ProtectedRoute><Perfil /></ProtectedRoute>} />
                <Route exact path="/Orcamento/:Guid" element={<ProtectedRoute><Orcamento /></ProtectedRoute>} />
                <Route exact path="/Avaliacoes" element={<ProtectedRoute><Avaliacoes /></ProtectedRoute>} />
                <Route exact path="/Pagamento/:PlanoId" element={<ProtectedRoute><Pagamento /></ProtectedRoute>} />
                <Route exact path="/Sobre" element={<ProtectedRoute><Sobre /></ProtectedRoute>} />
                <Route exact path="/Privacidade" element={<ProtectedRoute><Privacidade /></ProtectedRoute>} />
                <Route exact path="/Como" element={<ProtectedRoute><Como /></ProtectedRoute>} />
                <Route exact path="/Compartilhar" element={<ProtectedRoute><Compartilhar /></ProtectedRoute>} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}