/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import './index.css';


import { Context } from '../../../Context/AuthContext';


export default function Content() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [avaliacoes, setAvaliacoes] = useState({positivas:0, negativas:0});
    const [list, setList] = useState([]);
    const [stars, setStars] = useState(0);
    const [pageLoading, setPageLoading] = useState(false);

    const loadedRef = useRef(false);



     //#region Effects
     useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadAvaliacoes();
            }
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadAvaliacoes = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Avaliacoes/List?guid="+user.guid, "POST", {guid: user.guid}, user.token, 24*3600*1000))
        .then((data) => {
            //console.log(data);
            setStars(data.stars);
            ProcAvaliacoes(data.reports);
            setPageLoading(false);
        }).catch(() => {
            setPageLoading(false);
        });
    }
    //#endregion Loaders


    //#region Core
    const ProcAvaliacoes = (reports) => {
        let av = {positivas:0, negativas:0};
        let group_negativas = [];
        let group_positivas = [];
        reports.map((item, i) => {
            if(item.tipo_id===1) { 
                av.positivas++;
                if(group_positivas.length<=3)  
                {
                    group_positivas.push({type: item.tipo_id, message: item.mensagem, count:0});
                }
            } else { 
                av.negativas++
                let claims = item.content.split(',');
                console.log(claims);
                claims.map((claim, j) => {
                    let index = group_negativas.findIndex(a => a.message === claim.trim());
                    if(index === -1) {
                        group_negativas.push({type: item.tipo_id, message: claim.trim(), count:1})
                    } else {
                        group_negativas[index].count++;
                    }
                });
            }
        });

    
        let p100 = av.positivas+av.negativas;
        av.positivas = parseInt((av.positivas / p100) * 100);
        av.negativas = parseInt((av.negativas / p100) * 100);
        setAvaliacoes(av);

        let arr = group_positivas.concat(group_negativas);
        setList(arr);
    }
    //#endregion Core 



    return (
        <div className="AvaliacoesContent">
            <div className={'stars ' + (stars>=7?'star_green':(stars>=4?'star_yellow':'star_red')) }>
                <center>  {
                    [...Array(stars).keys()].map((i) => (<i key={'star'+i} className="bi bi-star-fill"></i>))
                }
                </center>
            </div>

            <div className="row text-center">
                <div className="col-6 mb-n2">
                    <a href="#" className="card card-style me-0" style={{height:'170px'}}>
                        <div className="_card-top mt-4">
                            <span className="icon icon-l rounded-m gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-hand-thumbs-up font-24 color-white"></i></span>
                            <h1 className="font-22 pt-3 line-height-m">Positivas</h1>
                        </div>
                        <div className="card-bottom">
                            <div className="progress rounded-xs bg-theme border border-green-light" style={{height:'20px'}}>
                                <div className="progress-bar gradient-green text-start ps-3 font-600 font-10"
                                    role="progressbar" style={{width: avaliacoes.positivas + '%'}} aria-valuenow="29" aria-valuemin="0"
                                    aria-valuemax="100">
                                    {avaliacoes.positivas}%
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-6 mb-n2">
                    <a href="#" className="card card-style ms-0" style={{height:'170px'}}>
                        <div className="_card-top mt-4">
                            <span className="icon icon-xl rounded-m gradient-orange shadow-bg shadow-bg-xs"><i className="bi bi-hand-thumbs-down font-24 color-white"></i></span>
                            <h1 className="font-22 pt-3">Negativas</h1>
                        </div>
                        <div className="card-bottom">
                            <div className="progress rounded-xs bg-theme border border-red-light" style={{height:'20px'}}>
                                <div className="progress-bar gradient-red text-start ps-3 font-600 font-10"
                                    role="progressbar" style={{width: avaliacoes.negativas + '%'}} aria-valuenow="29" aria-valuemin="0"
                                    aria-valuemax="100">
                                    {avaliacoes.negativas}%
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="card card-style">
                <br />

                {
                    list.length > 0 ? (
                        list.map((item, i) => (
                            <div key={'item'+i} className={'card card-style alert-dismissible show fade p-0 gradient-' + (item.type===1?'blue':'red') + ' shadow-bg shadow-bg-s'}>
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className={'bi ' + (item.type===1?'bi-check-circle-fill':'bi-emoji-frown-fill') +' font-36 d-block color-white scale-box'}></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                <b>{item.type===1?'':'('+item.count+') '}</b> {item.message===''?'Qualificação positiva sem comentário':item.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) 
                    : (<>
                        <div className="card card-style alert-dismissible show fade p-0 gradient-gray shadow-bg shadow-bg-s">
                        <div className="content my-3">
                            <div className="d-flex">
                                <div className="align-self-center no-click">
                                    <i className="bi bi-clock-history font-36 d-block color-white scale-box"></i>
                                </div>
                                <div className="align-self-center no-click">
                                    <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                        Nenhuma avaliação encontrada
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>)
                }
                

                {/*
                <div className="card card-style alert-dismissible show fade p-0 gradient-blue shadow-bg shadow-bg-s">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-check-circle-fill font-36 d-block color-white scale-box"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    Login Successful, <br /> Welcome back, John.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    Verification required. <br /> Please check your email.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-style bg-red-dark alert-dismissible show shadow-bg shadow-bg-m fade p-0">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-emoji-frown-fill font-36 d-block color-white"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    You don't have permission <br /> to perform this operation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>*/}


            </div>


            


        
        </div>
    )
}