/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { HubObjects } from '../../../scripts/HubObjects';
import { useNavigate } from 'react-router-dom';
import './index.css';

import { Context } from '../../../Context/AuthContext';



export default function Top() {
    const component_name = "home/top";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [validateMessage, setValidateMessage] = useState({ icon: "bi-clock", message: "Carregando..."});
    const [notificacoes, setNotificacoes] = useState(0);


    const timerIdleRef = useRef(new Date());
    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        Validade();

        return () => {
            clearInterval(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            } 
        }
    }, []);
    //#endregion Effects


    //#region Init
    const Init = () => {
        RegisterHub();
    }
    //#endregion Init

    
    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("SET_NOTIFICATIONS_COUNT", count => {
            setNotificacoes(count);
            return "loaded";
        }, component_name);
    }
    //#endegion HubObjects



    //#region Handlers
    const _HandleExit = () => {
        try {
            // eslint-disable-next-line no-undef
            invokeCSharpAction(JSON.stringify({cmd: "exit", data: ""}));
        }
        catch (err) {
            
        }
        navigate('/');
    }


    const _HandlerNClick = () => {
        document.getElementById("notificacoes").scrollIntoView({behavior: 'smooth'});
    }
    //#endregion Handlers
    


    //#region Functions
    const Validade = () => {
        if(user.validade !== "") {
            let dt = new Date();
            dt.setHours(0);
            dt.setMinutes(0);
            dt.setSeconds(0);

            let a = moment(user.validade + " 23:59:59");
            let b = moment(dt);
            let _diff = a.diff(b, 'days');

            //console.log(a.format('YYYY-MM-DD HH:mm:ss'), b.format('YYYY-MM-DD HH:mm:ss'), _diff)

            if(_diff < 0) {
                setValidateMessage({icon: "bi-exclamation-triangle", message: "Plano expirado a " + Math.abs(_diff) + " dias"});
            } else if(_diff === 0) {
                setValidateMessage({icon: "bi-exclamation-triangle", message: "Plano encerra em Hoje"});
            } else if(_diff <= 7) {
                setValidateMessage({icon: "bi-exclamation-triangle", message: "Plano encerra em " + _diff + " dias"});
            } else {
                setValidateMessage({icon: "bi-check2-circle", message: "Validade até " + a.format('DD/MM/YYYY')});
            }
        }
    }


    const FirstName = (name) => {
        if(name.indexOf(" ") > -1) {
            name = name.split(" ")[0];
        }
        return name;
    }
    //#endregion Functions


    return (
        <div className="pt-3">
            <div className="page-title d-flex">
                <div className="align-self-center me-auto">
                    <p className="color-highlight header-date">
                        <a><i className={'bi '+validateMessage.icon}></i> &nbsp; {validateMessage.message}&nbsp;</a>
                    </p>
                    <h1>Olá, {FirstName(user.nome)}</h1>
                </div>
                <div className="align-self-center ms-auto">
                    <a onClick={_HandlerNClick} data-bs-toggle="offcanvas" data-bs-target="#menu-notifications"
                        className="icon gradient-blue color-white shadow-bg shadow-bg-xs rounded-m">
                        <i className="bi bi-bell-fill font-17"></i>
                        {notificacoes > 0 ? (<em className="badge bg-red-dark color-white scale-box">{notificacoes}</em>) : (<></>)}
                    </a>
                    <a href="#" data-bs-toggle="dropdown"
                        className="icon gradient-blue shadow-bg shadow-bg-s rounded-m">
                        <img src="images/pictures/25s-1.jpg" width="45" className="rounded-m" alt="img" />
                    </a>

                    <div className="dropdown-menu">
                        <div className="card card-style shadow-m mt-1 me-1">
                            <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                                <LinkContainer to="/Perfil">
                                    <a className="list-group-item">
                                        <i className="has-bg gradient-green shadow-bg shadow-bg-xs color-white rounded-xs bi bi-person-lines-fill"></i>
                                        <strong className="font-13">Meu Cadastro</strong>
                                    </a>
                                </LinkContainer>
                                <LinkContainer to="/MeusPlanos">
                                <a className="list-group-item">
                                    <i className="has-bg gradient-blue shadow-bg shadow-bg-xs color-white rounded-xs bi bi-vector-pen"></i>
                                    <strong className="font-13">Meus Planos</strong>
                                </a>
                                </LinkContainer>
                                <a className="list-group-item" onClick={_HandleExit}>
                                    <i className="has-bg gradient-red shadow-bg shadow-bg-xs color-white rounded-xs bi bi-power"></i>
                                    <strong className="font-13">Sair</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}