/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import { useNavigate } from 'react-router-dom';
import './index.css';


import { Context } from '../../../Context/AuthContext';



export default function Content({...props}) {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [orcamento, setOrcamento] = useState({nome:""});
    const [pageLoading, setPageLoading] = useState(false);
    const [orcamentoData, setOrcamentoData] = useState({descricao: "Olá, sou " + user.nome + ", prestador de serviços em refrigeração...", valor: ""}); 
    const loadedRef = useRef(false);

    const navigate = useNavigate();
    const {Guid} = props;


    //#region Effects
    useEffect(() => {


        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                Init();
                LoadOrcamento(Guid);
            }
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        window.$(document).on('keydown', 'input[pattern]', function(e) {
            try {
                var input = window.$(this);
                var oldVal = input.val();
                var regex = new RegExp(input.attr('pattern'), 'g');
            
                setTimeout(function(){
                var newVal = input.val();
                if(!regex.test(newVal)){
                    input.val(oldVal); 
                }
                }, 1);
            } catch(e) { }
        });  
    }
    //#endregion Init


    
    //#region Loaders
    const LoadOrcamento = (guid) => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Orcamento/Load?guid="+guid, "POST", {guid: user.guid}, user.token, 10*1000))
        .then((data) => {
            if(data.orcamento_id !== "" && data.orcamento_id !== 0 && data.orcamento_id !== undefined) {
                setOrcamento(data);  
            }
            setPageLoading(false);
        }).catch(() => {
            setPageLoading(false);
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (e) => {
        let _data = Object.assign({}, orcamentoData);
        _data[e.target.id] = e.target.value;
        setOrcamentoData(_data);
    }

    const _HandleSend = () => {
        let _orc = Object.assign({}, orcamento);
        let _data = Object.assign({}, orcamentoData);
        if(_data.descricao!=="" && _data.valor!=="" && _data.valor!=="" && _orc.orcamento_id !== ""  && _orc.orcamento_id !== 0) {
            _data = Object.assign(_data, {orcamento_id: _orc.orcamento_id, guid: _orc.guid});
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Orcamento/Send", "POST", _data, user.token, 10*1000))
            .then((data) => {
                if(data.toString() === "loaded") {
                    try {
                        // eslint-disable-next-line no-undef
                        let result = invokeCSharpAction(JSON.stringify({cmd: "whatsapp", data: _orc.whatsapp + "|" + _data.descricao + "|" + _data.valor}));
                        if(result === "loaded") {
                            navigate('/Home');
                        } else {
                            window.swal("Alerta","Não foi possível abrir o Whatsapp!","error"); 
                        }
                    } catch(e) {
                        //web
                        console.log(data);
                    }
                } else if(data.toString() === "exists") {
                    window.swal("Alerta","Um orçamento já foi enviado","error");
                } else {
                    window.swal("Alerta","Ocorreu um erro no envio de dados!","error");
                }
                setPageLoading(false);
            }).catch((data) => { 
                setPageLoading(false);
            });
        } else {
            window.swal("Alerta","Preencha todos os campos corretamente!","error");
        }
    }
    //#endregion Handlers


    //#region Functions
    const FirstName = (name) => {
        if(name.indexOf(" ") > -1) {
            return name.split(" ")[0];
        }
        return name===""?"Assine hoje":name;
    }


    const RestName = (name) => {
        if(name.indexOf(" ") > -1) {
            let _name = name.split(" ");
            _name =_name.splice(1);
            return _name.join(' ');
        }
        return name===""?"para desbloquear":name;
    }


    const Modelo = (id) => {
        let arr = [
            {"id": 1, "name": "Split Hi Wall"},
            {"id": 2, "name": "Piso Teto"},
            {"id": 3, "name": "Cassete"},
            {"id": 4, "name": "Janela"},
            {"id": 99, "name": "Outros"},
            {"id": 98, "name": "Não sei informar"}
        ]

        try {
            return arr.find(v => v.id===id).name;
        } catch(e) {
            return '';
        }
    }


    const Problema = (id) => {
        let arr =  [
            {"id": 1, "name": "Sim"},
            {"id": 2, "name": "Não"},
            {"id": 98, "name": "Não sei informar"}
        ]

        try {
            return arr.find(v => v.id===id).name;
        } catch(e) {
            return '';
        }
    }


    const Btus = (id) => {
        let arr =  [
            {"id": 9, "name": "Até 9.000 btus"},
            {"id": 12, "name": "12.000 btus"},
            {"id": 18, "name": "18.000 btus"},
            {"id": 24, "name": "24.000 btus"},
            {"id": 30, "name": "30.000 btus"},
            {"id": 98, "name": "Não sei informar"}
        ]

        try {
            return arr.find(v => v.id==id).name;
        } catch(e) {
            return '';
        }
    }

    //#endregion Functions


    return (
        <div id="page" className="Orcamento">
            <div className="card card-style overflow-visible mt-5">
                <h1 className="color-theme text-center font-30 pt-3 mb-0">{FirstName(orcamento.nome)}</h1>
                <p className="text-center font-11"><i className={'bi ' + (orcamento.nome===''?'bi-exclamation-triangle-fill color-red-dark':'bi-check-circle-fill color-green-dark') + ' pe-2'}></i>{RestName(orcamento.nome)}</p>
                <div className="content mt-0 mb-2">
                    <div className="list-group list-custom list-group-flush list-group-m rounded-xs">
                        <div className="list-group-item">
                            <div>{orcamento.obs}</div>
                        </div>

                        <div className="list-group-item">
                            <i className="bi bi-gear-fill"></i>
                            <div>{orcamento.servico}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-cpu-fill"></i>
                            <div>{orcamento.aparelho}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className={'list-group-item '+(orcamento.btus===''||orcamento.aparelho_id!=='1'?'hide':'')}>
                            <i className="bi bi-battery-charging"></i>
                            <div>{Btus(orcamento.btus)}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className={'list-group-item '+(orcamento.modelo_id===''||orcamento.modelo_id==='0'||orcamento.aparelho_id!=='1'?'hide':'')}>
                            <i className="bi bi-wind"></i>
                            <div>{Modelo(orcamento.modelo_id)}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className={'list-group-item '+(orcamento.problema_id===''&&orcamento.problema_id==='0'?'hide':'')}>
                            <i className="bi bi-bug-fill"></i>
                            <div>Apresenta problema: {Problema(orcamento.problema_id)}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-binoculars-fill"></i>
                            <div>Distância: <b>{orcamento.distance}</b> km</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-geo-alt-fill"></i>
                            <div>{orcamento.cidade}-{orcamento.uf}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-style overflow-visible">
                <div className="content mt-0 mb-2">
                    <div className="list-group list-custom list-group-flush list-group-m rounded-xs">
                        <div style={{width:'100%'}}>
                            <div className='clititle'>
                                <center><h5>INFORMAÇÕES DO CLIENTE</h5></center>
                            </div>
                        </div>
                        { orcamento.nome !== '' && orcamento.nome !== undefined ? (<>
                        <div className="list-group-item">
                            <i className="bi bi-person-check"></i>
                            <div className="limit">{orcamento.nome}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-geo-alt"></i>
                            <div>{orcamento.endereco}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-house"></i>
                            <div>Número: <b>{orcamento.numero}</b></div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-bookmark-check"></i>
                            <div>{orcamento.bairro}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-envelope-open"></i>
                            <div>{orcamento.email}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div className="list-group-item">
                            <i className="bi bi-whatsapp"></i>
                            <div>{orcamento.whatsapp}</div>
                            <i className="bi bi-chevron-right"></i>
                        </div>
                        <div style={{width:'100%'}}>
                            <div className='clititle'>
                                <center><h5>CRIAR ORÇAMENTO</h5></center>
                            </div>
                        </div>
                    
                        <div className="form-custom form-label form-icon mb-3">
                            <i className="bi bi-pencil-fill font-14"></i>
                            <textarea className="form-control rounded-xs" placeholder="" id="descricao" value={orcamentoData.descricao} onChange={_HandleChange}>{orcamentoData.descricao}</textarea>
                            <label for="descricao" className="form-label-always-active">Descrição do Orçamento</label>
                        </div>
                       
                        

                        <div className="form-custom form-label form-icon mb-3">
                            <i className="bi bi-wallet2 font-16"></i>
                            <input type="number" className="form-control rounded-xs" id="valor" value={orcamentoData.valor} onChange={_HandleChange} step=".01" required="true" inputmode="numeric" pattern="^\d*(\.\d{0,2})?$"/>
                            <label for="valor" className="form-label-always-active">Valor estimado em R$</label>
                        </div>

                        <small>No prosseguimento da conversa com o cliente é importante informar que o valor pode sofrer modificações dependendo da natureza do serviço.</small>
                        
                        </>) : (<>
                            <a className="list-group-item">Para acessar as informações do cliente e poder responder ao pendido de orçamento você precisa ser assinante de qualquer plano disponível</a>
                        </>) }
                    </div>
                </div>
            </div>
            { orcamento.nome !== '' && orcamento.nome !== undefined ? (<>
                <a className={'btn btn-full mx-3 shadow-bg shadow-bg-xs ' + (((orcamentoData.descricao!=="" && orcamentoData.valor!=="" && orcamentoData.valor!==""))?'gradient-highlight':'gradient-gray')} onClick={_HandleSend}>Enviar Orçamento</a>
            </>) : (<>
                <LinkContainer to="/Planos">
                <a className="btn btn-full mx-3 gradient-highlight shadow-bg shadow-bg-xs">Assine Agora</a>
                </LinkContainer>
            </>) }
            <br />
        </div>
    )
}