import React, {useState} from 'react';


export default function Layout({ children }) {

  return (
      <React.Fragment>
        {children}
      </React.Fragment>
  )
}