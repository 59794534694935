/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';


import { Context } from '../../../Context/AuthContext';




export default function Lista({...props}) {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [planos, setPlanos] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const {PlanoId} = props;

    const loadedRef = useRef(false);


     //#region Effects
     useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadPlanos();
            }
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


     //#region Loaders
     const LoadPlanos = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Home/Planos", "POST", {guid: user.guid}, user.token, 10*1000))
        .then((data) => {
            if(data.length > 0) {
                data = data.filter(item => {return (item.valor-item.bonus) > 1.80 || (item.valor-item.bonus) <= 0} );
                setPlanos(data);
                HubObjects.Set("planos", data);
                setPageLoading(false);
                setTimeout(() => {
                    SelectAccordion(parseInt(PlanoId));
                }, 300);
            }
        }).catch(() => {
            setPageLoading(false);
        });
    }
    //#endregion Loaders


    //#region Functions
    const SelectAccordion = (id) => {
        for(let i=1; i<=10; i++) {
            if(i === id) {
                window.$('#accordionA-'+i).collapse("show");
                document.getElementById("accordionA-"+i).scrollIntoView({behavior: 'smooth'});
            } else {
                try {
                    window.$('#accordionA-'+i).collapse("hide");
                } catch (e) {}
            }
        }
    }
    //#endregion Functions


    return (
        <div className="PlanosLista">
            <h6 className="font-700 opacity-50 pb-2"> *Todas as assinaturas são cumulativas</h6>
            <div className="accordion accordion-m border-0" id="accordion-group-7">
                {
                    planos.length > 0 ? (
                        planos.map((item, i) => (
                            <div key={'plan'+i} className={'accordion-item border border-fade-highlight border-bottom-0' + (i===0?' rounded-top rounded-m':(i===(planos.length-1)?' rounded-bottom rounded-m':' border-bottom-0'))}>
                                <button id={'accordionB-'+item.plano_id} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#accordionA-'+item.plano_id}>
                                    <i className="bi bi-star-fill color-yellow-dark pe-3 font-14"></i>
                                    <span className="font-600 font-13">{item.name}</span>
                                    <span className={'font-300 font-13 '} style={{color:'red'}}> &nbsp;  {Currency(item.valor-item.bonus)} {item.bonus>0?'*':''}</span>
                                    <i className={'bi ' + (i===(planos.length-1)?'bi-arrow-down-short':'bi-plus') +' font-20'}></i>
                                </button>
                                <div id={'accordionA-'+item.plano_id} className="accordion-collapse collapse" data-bs-parent="#accordion-group-7">
                                    <div className="row px-3 mb-0 pb-3 pt-1">
                                        <div className="col-4"><img src={'/images/pictures/'+item.image} className=" rounded-xs" alt=""/></div>
                                        <div className="col-8">{item.obs}<br />
                                            <small style={{color:'#4a89dc'}}>{item.bonus>0?'Desconto de ' + Currency(item.bonus):''}</small>
                                        </div>

                                        <LinkContainer to={'/Pagamento/'+item.plano_id}>
                                            <a className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4" >ASSINAR AGORA</a>
                                        </LinkContainer>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (<></>) 
                }


                {/* 
                <div className="accordion-item border border-fade-highlight border-bottom-0 rounded-top rounded-m">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion7-1">
                        <i className="bi bi-heart-fill color-red-dark pe-3 font-14"></i>
                        <span className="font-600 font-13">Accordion Item Text</span>
                        <i className="bi bi-plus font-20"></i>
                    </button>
                    <div id="accordion7-1" className="accordion-collapse collapse"
                        data-bs-parent="#accordion-group-7">
                        <p className="px-3 mb-0 pb-3 pt-1">
                            This is the accordion body. It can support most content you want without
                            restrictions. You can use
                            images, videos lists or whatever you want.
                        </p>
                    </div>
                </div>


                <div className="accordion-item border border-fade-highlight border-bottom-0">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion7-2">
                        <i className="bi bi-star-fill color-yellow-dark pe-3 font-14"></i>
                        <span className="font-600 font-13">Accordion Item Image</span>
                        <i className="bi bi-plus font-20"></i>
                    </button>
                    <div id="accordion7-2" className="accordion-collapse collapse"
                        data-bs-parent="#accordion-group-7">
                        <div className="p-2 px-3"><img src="/images/pictures/1w.jpg" className="img-fluid rounded-s"  alt=""/>
                        </div>
                        <h5 className="px-3 font-700">This is an Image</h5>
                        <p className="px-3 mb-0 pb-3 opacity-70">
                            This is the accordion body. It can support most content you want without
                            restrictions. You can use
                            images, videos lists or whatever you want.
                        </p>
                    </div>
                </div>


                <div className="accordion-item border border-fade-highlight rounded-bottom rounded-m">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion7-3">
                        <i className="bi bi-check-circle-fill color-green-dark pe-3 font-14"></i>
                        <span className="font-600 font-13">Accordion Item Grid</span>
                        <i className="bi bi-arrow-down-short font-20"></i>
                    </button>
                    <div id="accordion7-3" className="accordion-collapse collapse"
                        data-bs-parent="#accordion-group-7">
                        <div className="row px-3 pb-3 pt-1">
                            <div className="col-4"><img src="/images/pictures/1s-1.jpg" className="img-fluid rounded-xs" alt=""/>
                            </div>
                            <div className="col-4"><img src="/images/pictures/2s-1.jpg" className="img-fluid rounded-xs"  alt=""/>
                            </div>
                            <div className="col-4"><img src="/images/pictures/3s.jpg" className="img-fluid rounded-xs"  alt=""/>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    )

}