import React, { useContext, useState, useEffect, useRef } from 'react';
import './index.css';


import { Context } from '../../../Context/AuthContext';


export default function Content() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);



    //#region Handlers
    const _HandleClipBoard = (txt) => {
        try {
            // eslint-disable-next-line no-undef
            invokeCSharpAction(JSON.stringify({cmd: "clipboard", data: txt}));
        } catch(e) {
            
        }

        try {
            navigator.clipboard.writeText(txt);
        } catch(e) {
           
        }

        window.swal("Sucesso", "Link copiado com sucesso", "success");
    }


    const _HandleWhatsapp = (txt) => {
        try {
            // eslint-disable-next-line no-undef
            let result = invokeCSharpAction(JSON.stringify({cmd: "share", data: txt}));
            if(result === "loaded") {
                
            } else {
                window.swal("Alerta","Não foi possível abrir o Whatsapp!","error"); 
            }
        } catch(e) {
            
        }
    }
    //#endregion Handlers


    return (
        <div id="page" className="Compartilhar">

            <div className="page-content footer-clear card card-style">
                <center><h2>Bônus de engajamento</h2></center>
                <br />
                <p>Ganhe bônus na renovação de sua parcela engajando clientes na plataforma i2play, a cada pedido de orçamentos gerados atravez do seu link personalizado você ganha 10% de desconto na renovação do plano básico mensal* (cumulativo até 50%)</p>



                <div className={'row '}>
                    <center>
                        <div onClick={() => _HandleClipBoard("https://i2play.com.br/Orcamento/"+user.guid)}>
                            <div>Link para engajamento de clientes</div>
                            <div className="form-control rounded-xs"><b>https://i2play.com.br/Orcamento/{user.guid}</b></div>
                            <div><small>Toque para copiar para área de transferência</small></div>
                        </div>
                    </center>
                </div>

                <button type="button" className={'btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4'} onClick={()=>_HandleWhatsapp("https://i2play.com.br/Orcamento/"+user.guid)}>Compartilhar no dispositivo</button>

                <br />
                <hr />
                <br />
                <center><h2>Bônus de afiliado</h2></center>
                <br />
                <p>Ganhe bônus na renovação de sua parcela engajando novos profissionais i2play, a cada profissional que se cadastrar na plataforma você ganha 10% de desconso na renovação do plano básico mensal* (cumulativo até 50%)</p>



                <div className={'row '}>
                    <center>
                        <div onClick={() => _HandleClipBoard("https://i2play.com.br/Cadastro/"+user.guid)}>
                            <div>Link para engajamento de profissionais</div>
                            <div className="form-control rounded-xs"><b>https://i2play.com.br/Cadastro/{user.guid}</b></div>
                            <div><small>Toque para copiar para área de transferência</small></div>
                        </div>
                    </center>
                </div>

                <button type="button" className={'btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4'} onClick={()=>_HandleWhatsapp("https://i2play.com.br/Cadastro/"+user.guid)}>Compartilhar no dispositivo</button>
            </div>
        </div>
    )
}