/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Request } from '../../../scripts/Request';
import { Uuid, RemoveAcentos, Currency, IsCPF } from '../../../scripts/StringUtils';
import { HubObjects } from '../../../scripts/HubObjects';
import { useNavigate } from 'react-router-dom';
import './index.css';


import { Context } from '../../../Context/AuthContext';


export default function Content({...props}) {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [sessionId, setSessionId] = useState("");
    const [plano, setPlano] = useState({name: "", valor: "", obs: ""});
    const [tipoPagamento, setTipoPagamento]= useState(1);
    const [paymentMethods, setPaymentMethods] = useState("");
    const [card, setCard] = useState({nome:"", numero:"", bandeira: "", validade:"", cvv: "", cpf_holder: "", email: ""});
    const [cards, setCards] = useState([]);
    const [boleto, setBoleto] = useState({link: "", barcode:""});
    const [pix, setPix] = useState({txid: "", pixCopiaECola:"", urlImagemQrCode:"", status:""});
    const loadedRef = useRef(false);

    const _planoRef = useRef({name: "", valor: "", obs: ""});
    const _sessionId = useRef("");
    const _cardsRef = useRef([]);
    const _dataRef = useRef({
        plano_id:"",
        payment: {
            mode: "default",
            method: "creditCard",
            sender: {
                name: "",
                email: "",
                phone: {
                    areaCode: "",
                    number: ""
                },
                documents: [
                    {
                        document: {
                            type: "CPF",
                            value: ""
                        }
                    }
                ],
                hash: ""
            },
            currency: "BRL",
            notificationURL: "https://i2play.com.br/api/PagSeguro/Notification",
            items: [
                {
                    item: {
                        id: "",
                        description: "",
                        quantity: 1,
                        amount: 0.00
                    }
                }
            ],
            extraAmount: 0.00,
            reference: "",
            shipping: {
                addressRequired: false
            },
            creditCard: {
                token: "",
                number: "",
                exp_month: "",
                exp_year: "",
                security_code: "",
                encripted: "",
                brand: "",
                cvv: "",
                expirationMonth: "",
                expirationYear: "",
                installment: {
                    quantity: 1,
                    value: 0.00
                },
                holder: {
                    name: "",
                    documents: [
                        {
                            document: {
                                type: "CPF",
                                value: ""
                            }
                        }
                    ],
                    birthDate: "00/00/0000",
                    phone: {
                        areaCode: "",
                        number: ""
                    },
                },
                billingAddress: {
                    street: "",
                    number: "1384",
                    complement: "",
                    district: "",
                    city: "",
                    state: "",
                    country: "BRA",
                    postalCode: ""
                }
            }
        }
    });

    const navigate = useNavigate();
    const {PlanoId} = props;



    //#region Effects
    useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                setTimeout(async()=> {
                    await LoadPlano();
                    await LoadMe();
                    Init();

                    let wait_pgapi = setInterval( async() => {
                        if (window.PagSeguroDirectPayment) {
                            clearInterval(wait_pgapi);
                            await LoadSessionId();
                        } else {
                            console.log("Waiting PagSeguroDirectPayment");
                        }
                    }, 300);
                },1);
            }
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


     //#region Init
     const Init = () => {
        window.$('#validade').mask('00/0000', {placeholder: "__/____"});
        window.$('#cvv').mask('000');
     }
     //#endregion Init


    //#region Loaders
    const LoadMe = async() => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/PagSeguro/Me", "GET", {}, user.token))
            .then((data) => {
                _dataRef.current.payment.sender.name = data.nome;
                _dataRef.current.payment.sender.email = data.email;

                let _card = Object.assign({}, card);
                _card.nome = data.nome;
                _card.email = data.email;
                _card.cpf_holder =  data.cpf.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
                setCard(_card);

                //console.log(_card);

                let _phone = data.telefone;
                _phone = _phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "");
                let _areaCode = _phone.split(' ')[0];
                let _number = _phone.split(' ')[1];

                _dataRef.current.payment.sender.phone.areaCode = _areaCode;
                _dataRef.current.payment.sender.phone.number = _number;
                _dataRef.current.payment.sender.documents[0].document.type = parseInt(data.pessoa) === 0 ? "CPF" : "CNPJ";
                _dataRef.current.payment.sender.documents[0].document.value = data.cpf.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
                _dataRef.current.payment.reference = "i2play_" + Uuid();
                _dataRef.current.payment.creditCard.holder.name = RemoveAcentos(data.nome);
                _dataRef.current.payment.creditCard.holder.documents[0].document.type = data.pessoa === 0 ? "CPF" : "CNPJ";
                _dataRef.current.payment.creditCard.holder.documents[0].document.value = data.cpf.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
                _dataRef.current.payment.creditCard.holder.phone.areaCode = _areaCode;
                _dataRef.current.payment.creditCard.holder.phone.number = _number;
                //_dataRef.current.payment.creditCard.holder.birthDate = moment(_formUserRef.current.GetValue("birthDate")).format("DD/MM/YYYY");
                _dataRef.current.payment.creditCard.billingAddress.street = RemoveAcentos(data.endereco);
                _dataRef.current.payment.creditCard.billingAddress.number = data.numero;
                _dataRef.current.payment.creditCard.billingAddress.district = RemoveAcentos(data.bairro);
                _dataRef.current.payment.creditCard.billingAddress.complement = RemoveAcentos(data.complemento);
                _dataRef.current.payment.creditCard.billingAddress.state = data.uf;
                _dataRef.current.payment.creditCard.billingAddress.city = RemoveAcentos(data.cidade);
                _dataRef.current.payment.creditCard.billingAddress.postalCode = data.cep.replaceAll(".", "").replaceAll("-", "");

                console.log( _dataRef.current);
                setPageLoading(false);
            });
    }


    const LoadSessionId = async() => {
        await Promise.resolve(new Request().Run("api/PagSeguro/Session", "GET", {}, user.token))
            .then((data) => {
                setSessionId(prev => prev = data);
                window.PagSeguroDirectPayment.setSessionId(data);
                _sessionId.current = data;
                setPageLoading(false);
                LoadPaymentMethods(_planoRef.current);
            });
    }


    const LoadPlano = async() => {
        Promise.resolve(new Request().Run("api/Home/Planos", "POST", {guid: user.guid}, user.token, 10*1000))
            .then((data) => {
                let plano = data.find(p => p.plano_id===parseInt(PlanoId));
                if(plano) {
                    _planoRef.current = plano; 
                    setPlano(plano);
                } else {
                    window.swal("Alerta", "Plano não identificado", "error");
                    navigate("/Planos");
                }
            }).catch(() => {
                setPageLoading(false);
            });
    }
    //#endregion Loaders


    //#region Handler
    const HandlerCardInfoChange = (event) => {
        //console.log(event.target.id,event.target.value);
        //setCard({...cards, [event.target.id]: event.target.value});

        let _card = Object.assign({}, card);
        _card[event.target.id] = event.target.value;
        setCard(_card);

        if(event.target.id === "cpf_holder") {
            _dataRef.current.payment.creditCard.holder.documents[0].document.value = event.target.value;
            _dataRef.current.payment.sender.documents[0].document.value = event.target.value;
        }

        if(event.target.id === "nome") {
            _dataRef.current.payment.creditCard.holder.name = event.target.value;
            _dataRef.current.payment.sender.name = event.target.value;
        }

        if(event.target.id === "email") {
            _dataRef.current.payment.sender.email = event.target.value;
        }
    }


    const _HandleClipBoard = (barcode, message) => {
        try {
            // eslint-disable-next-line no-undef
            invokeCSharpAction(JSON.stringify({cmd: "clipboard", data: barcode}));
        } catch(e) {
            
        }

        try {
            navigator.clipboard.writeText(barcode);
        } catch(e) {
           
        }

        window.swal("Sucesso", message, "success");
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let  _card = Object.assign({}, card);
        console.log(_card);
        let _cpf = _card.cpf_holder;
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }
  
        let tipo = tipoPagamento;
        if(tipo === 1) {
            ValidCard();
        } else if(tipo === 2) {
            HandleBoleto();
        } else if(tipo === 3) {
            HandlePix();
        }
    }
    //#endregion Handler


    //#region Core
    const LoadPaymentMethods = (plano) => {
        _dataRef.current.plano_id = PlanoId;
        _dataRef.current.payment.items[0].item.id = PlanoId;
        _dataRef.current.payment.items[0].item.amount = (plano.valor-plano.bonus).toFixed(2).toString();
        _dataRef.current.payment.items[0].item.quantity = 1;
        _dataRef.current.payment.items[0].item.description = RemoveAcentos(plano.name);

        //console.log(plano);

        window.PagSeguroDirectPayment.getPaymentMethods({
            amount: plano.valor,
            success: function (response) {
                // Retorna os meios de pagamento disponíveis.
                setPaymentMethods(response);
                LoadCards(response);
            },
            error: function (response) {
                // Callback para chamadas que falharam.
                console.log("error", response);
                return null;
            },
            complete: function (response) {
                // Callback para todas chamadas.
            }
        });
    }

    
    const LoadCards = (data) => {
        if (_cardsRef.current.length > 0) return;
        let arr = data.paymentMethods.CREDIT_CARD.options;

        let keys = Object.keys(arr);
        let cards = [];

        keys.map((item, i) => {
            if (arr[item].status === "AVAILABLE") {
                cards.push({ id: arr[item].code, name: arr[item].name });
            }
        });

        _cardsRef.current = cards;
        setCards(cards);
    }


    const ValidCard = () => {
        let  _card = Object.assign({}, card);
        let _validade = card.validade;
        let _cardNumber = card.numero;
        let _brand = card.bandeira;
        let _cvv = card.cvv;
        let _nome = card.nome;

        //console.log(_card);

        if(_validade.length === 5) {
            window.swal("Alerta", "Use o ano completo na validade Ex: 07/2035", "error");
            return;
        }

        try {
            if (_validade.indexOf("/") === -1 || 
                _cardNumber === "" ||
                _brand === "" ||
                _cvv === "" ||
                _nome === "" ||
                _cardNumber === null ||
                _brand === null ||
                _cvv === null ||
                _cardNumber === undefined ||
                _brand === undefined ||
                _cvv === undefined ||
                _nome === undefined ||
                _validade.length < 5 ||
                _cardNumber.length < 16
            ) {
                window.swal("Alerta", "Informações do cartão incorretas!", "error");
                return;
            }

            let _month = _validade.split("/")[0];
            let _year = _validade.split("/")[1];

            let card = window.PagSeguro.encryptCard({
                publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAllCsyQOz6trBhD1+ljGEEtFoqGbY6l7nUOpNvxd2Wf4qzIhanV6G0QUa4YbnQwxid3fkGXYMQ0kYHT4Ya5y+admroycYQ1VaUbjLPts8UkQak6pQbDSY/Tm+TudTykBel5vz1/y8+WGrjnpcBX7HrNzUhAgj5lhvty2iYY2Gtpx9q4Zxx2oqQAVP4S9Ygd8OtABf8/bPdLokr6m4l+cQ0JKt7xKAXZJ+lXz7m7P/aV1hggRNt9BAdlFhHThe/Vty1Wtr3bcttnFtZPAj30LA8NAZm+L7T/SJhJLrSDueqgOFu9d5rQG/JvAoCa2r/yd8CE2IrN06iwlOdgrSUEkyPwIDAQAB",
                holder: RemoveAcentos(_nome),
                number: _cardNumber,
                expMonth: _month,
                expYear: _year,
                securityCode: _cvv,
              });
              
            let encrypted = card.encryptedCard

            console.log(encrypted);

            let c = 0;
            let wait_encript = setInterval(() => {
                if(encrypted !== "" && encrypted !== undefined && encrypted !== null) {
                    clearInterval(wait_encript);
                    createCardToken({
                        cardNumber: _cardNumber,
                        brand: _brand,
                        cvv: _cvv,
                        expirationMonth: _month,
                        expirationYear: _year,
                        encrypted: encrypted
                    });
                } else {
                    //console.log("wait_encript");
                    c++;
                    if(c>=10) {
                        clearInterval(wait_encript);
                        window.swal("Alerta", "Informações do cartão incorretas!", "error");
                        return;
                    }
                }
                
            }, 100);
        } catch (e) { }
    }


    const createCardToken = async(card) => {
        setPageLoading(true);

        _dataRef.current.payment.creditCard.encrypted = card.encrypted;
        //console.log(_dataRef.current);

        await Promise.resolve(new Request().Run("api/PagSeguro/Cartao", "POST", _dataRef.current, user.token))
            .then((data) => {
                setPageLoading(false);

                //console.log(data);

                if (data.indexOf("CHAR") > -1 && data.indexOf("i2play_") > -1 ) {
                    window.swal({
                        title: "Sucesso",
                        text: "Compra finalizada, o acesso será liberado após processamento do pagamento!",
                        type: "success",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        closeOnConfirm: true,
                        closeOnCancel: false
                    },
                        function(isConfirm){
                        navigate("/Home");
                    });
                } else {
                    window.swal("Alerta", "Não foi possível processar o pagamento, confira as informações digitadas e tente novamente mais tarde.", "warning");
                }
            }).catch(()=> {
                setPageLoading(false);
            });
    }


    const HandleBoleto = async() => {
        setPageLoading(true);

        //console.log(_dataRef.current);

        await Promise.resolve(new Request().Run("api/PagSeguro/Boleto", "POST", _dataRef.current, user.token))
            .then((data) => {     
                //console.log(json.links[0].href);
                //console.log(json.payment_method.boleto.barcode, json.payment_method.boleto.formatted_barcode);

                if (data.indexOf("CHAR") > -1 && data.indexOf("i2play_") > -1 ) {
                    /*window.swal({
                        title: "Sucesso",
                        text: "Compra finalizada, o acesso será liberado após processamento do pagamento!",
                        type: "success",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        closeOnConfirm: true,
                        closeOnCancel: false
                    },
                        function(isConfirm){
                        navigate("/Home");
                    });*/
                    let json = JSON.parse(data);
                    setBoleto({link: json.links[0].href, barcode: json.payment_method.boleto.formatted_barcode})
                } else {
                    window.swal("Alerta", "Não foi possível processar o pagamento, confira as informações digitadas e tente novamente mais tarde.", "warning");
                }
            }).catch(()=> {
                setPageLoading(false);
            });
        setPageLoading(false);

    }


    const HandlePix = async() => {
        setPageLoading(true);

        //console.log(_dataRef.current);

        await Promise.resolve(new Request().Run("api/PagSeguro/Pix", "POST", _dataRef.current, user.token))
            .then((data) => {
                let json = data;
                console.log(json);

                if (json.pixCopiaECola !== "" && json.status === "ATIVA") {
                    setPix(json);
                } else {
                    window.swal("Alerta", "Não foi possível processar o pagamento, confira as informações digitadas e tente novamente mais tarde.", "warning");
                }
            }).catch(()=> {
                setPageLoading(false);
            });

        setPageLoading(false);
    }


    const HandleFreeRenew = async() => {
        setPageLoading(true);

        await Promise.resolve(new Request().Run("api/PagSeguro/FreeBonus", "POST", _dataRef.current, user.token))
            .then((data) => {
                setPageLoading(false);

                //console.log(data);

                if (data.toString() === "loaded") {
                    window.swal({
                        title: "Sucesso",
                        text: "Bonus gratuito aplicado com sucesso!",
                        type: "success",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        closeOnConfirm: true,
                        closeOnCancel: false
                    },
                        function(isConfirm){
                        navigate("/Home");
                    });
                } else {
                    window.swal("Alerta", "Não foi possível processar o pagamento, confira as informações digitadas e tente novamente mais tarde.", "warning");
                }
            });
    }
    //#endregion Core


     //#region Functions
    const SaveToDisk = (fileURL, fileName) => {
        try {
            // eslint-disable-next-line no-undef
            invokeCSharpAction(JSON.stringify({cmd: "download", data: fileURL}));
            window.swal("Sucesso", "Download do boleto em andamento", "success");
        } catch(e) {
            
        }


        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName || 'unknown';
    
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
    
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    
        // for IE < 11
        else if ( !! window.ActiveXObject && document.execCommand)     {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }
    //#endregion Functions


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
             let _card = Object.assign({}, card);
            //_card.bandeira = "101";
            _card.numero = "4111111111111111";
            _card.cvv ="123";
            _card.validade = "12/2030";
            //_card.nome = "Felipe Martins dos Santos";
            //_card.cpf_holder = "315.757.808-10";
            console.log(_card);
            setCard(_card);
        }
    });*/
    //#endregion Tests


    return (
        <div className="Pagamento">
            
            <div className="card card-style">
                
                <div className="content">
                    <div>
                        <div className="row">
                            <div className="col-4"><img src={'/images/pictures/'+plano.image} className=" rounded-xs" alt=""/></div>
                            <div className="col-8">{plano.name}<br />
                                <span className={plano.bonus>0?'risk':'hide'} style={{color:(plano.bonus>0?' ':'red')}}><b>{Currency(plano.valor)}</b></span> 
                                <span style={{color:'red'}}><b>  &nbsp;{Currency(plano.valor-plano.bonus)}</b></span>
                            </div>

                        </div>
                    </div>
                    <hr />

                    {(plano.valor-plano.bonus) > 0 ? (

                        <div className="tabs tabs-pill" id="tab-group-2">
                            <div className="tab-controls rounded-m p-1">
                                <a className="font-12 rounded-m" data-bs-toggle="collapse" href="#tab-1" aria-expanded="true" onClick={()=> setTipoPagamento(1)}>Cartão</a>
                                <a className="font-12 rounded-m" data-bs-toggle="collapse" href="#tab-2" aria-expanded="false" onClick={()=> setTipoPagamento(2)}>Boleto</a>
                                <a className="font-12 rounded-m" data-bs-toggle="collapse" href="#tab-3" aria-expanded="false" onClick={()=> setTipoPagamento(3)}>Pix</a>
                            </div>
                            <div className="mt-3"></div>

                            <div className="collapse show" id="tab-1" data-bs-parent="#tab-group-2">
                                <br />
                                <form name="fcard" onSubmit={(event) => _HandleSubmit('rcard', event)} autoComplete="off" id="form-contact">
                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-credit-card font-13"></i>
                                            <select className="form-select rounded-xs" id="bandeira" onChange={HandlerCardInfoChange} value={card.bandeira}>
                                                {
                                                    cards.length > 0 ? (
                                                        cards.map((item, i) => (
                                                            <option key={'brand'+i} value={item.id}>{item.name}</option>
                                                        ))
                                                    ) : (<></>)
                                                }
                                            </select>
                                            <label for="bandeira" className="form-label-always-active color-blue-dark">Bandeira do cartão</label>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-shield-minus font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="numero" onChange={HandlerCardInfoChange} value={card.numero} placeholder="" required={true} />
                                            <label for="numero" className="form-label-always-active color-blue-dark">Número do cartão</label>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-person font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="nome" onChange={HandlerCardInfoChange} value={card.nome} placeholder="" required={true} />
                                            <label for="nome" className="form-label-always-active color-blue-dark">Nome do titular</label>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-6 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-calendar-check font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="validade" onChange={HandlerCardInfoChange} value={card.validade} placeholder="" required={true}/>
                                            <label for="validade" className="form-label-always-active color-blue-dark">Validade</label>
                                        </div> 

                                        <div className="col-6 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-lock font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="cvv" onChange={HandlerCardInfoChange} value={card.cvv} placeholder="" required={true} />
                                            <label for="c1d" className="form-label-always-active color-blue-dark">CVV</label>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-file-earmark-check font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="cpf_holder" onChange={HandlerCardInfoChange} value={card.cpf_holder} placeholder="" required={true} />
                                            <label for="c1d" className="form-label-always-active color-blue-dark">CPF do titular</label>
                                        </div> 
                                    </div>
                                
                                    <button type="submit" className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4" >EFETUAR PAGAMENTO</button>
                                </form>
                            </div>

                            <div className="collapse" id="tab-2" data-bs-parent="#tab-group-2">
                                <br />
                                <form name="fcard" onSubmit={(event) => _HandleSubmit('rcard', event)} autoComplete="off" id="form-contact">
                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-person font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="nome" onChange={HandlerCardInfoChange} value={card.nome} placeholder="" required={true} />
                                            <label for="nome" className="form-label-always-active color-blue-dark">Nome do sacado</label>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-file-earmark-check font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="cpf_holder" onChange={HandlerCardInfoChange} value={card.cpf_holder} placeholder="" required={true} />
                                            <label for="c1d" className="form-label-always-active color-blue-dark">CPF do sacado</label>
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-12 form-custom form-label form-icon mb-3">
                                            <i className="bi bi-file-earmark-check font-13"></i>
                                            <input type="text" className="form-control rounded-xs" id="email" onChange={HandlerCardInfoChange} value={card.email} placeholder="" required={true} />
                                            <label for="c1d" className="form-label-always-active color-blue-dark">Email do sacado</label>
                                        </div> 
                                    </div>

                        

                                    <div className={'row '+(boleto.barcode===''?'hide':'')}>
                                        <center>
                                            <div onClick={() => _HandleClipBoard(boleto.barcode, "Código de barra copiado")}>
                                                <div>Linha digitável</div>
                                                <div className="form-control rounded-xs"><b>{boleto.barcode}</b></div>
                                                <div><small>Toque para copiar para área de transferência</small></div>
                                            </div>
                                        </center>
                                    </div>

                                    <button type="submit" className={'btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4 ' + (boleto.barcode===''?'':'hide')} >GERAR BOLETO</button>
                                    <button type="button" className={'btn btn-full gradient-red shadow-bg shadow-bg-s mt-4 ' + (boleto.barcode===''?'hide':'')} onClick={() => SaveToDisk(boleto.link, "i2play_boleto")}>DOWNLOAD DO BOLETO</button>
                                </form>
                            </div>

                            <div className="collapse" id="tab-3" data-bs-parent="#tab-group-2">
                                <br />
                                {
                                    user.loja_id === 0 ? (<>
                                    <br /> <center><h2>Não disponível no momento</h2></center><br/><br/>
                                    </>) : (<>
                                        <form name="fpix" onSubmit={(event) => _HandleSubmit('rpix', event)} autoComplete="off" id="form-contact">
                                            <div className="row">
                                                <div className="col-12 form-custom form-label form-icon mb-3">
                                                    <i className="bi bi-person font-13"></i>
                                                    <input type="text" className="form-control rounded-xs" id="nome" onChange={HandlerCardInfoChange} value={card.nome} placeholder="" required={true} />
                                                    <label for="nome" className="form-label-always-active color-blue-dark">Nome do pagador</label>
                                                </div> 
                                            </div>

                                            <div className="row">
                                                <div className="col-12 form-custom form-label form-icon mb-3">
                                                    <i className="bi bi-file-earmark-check font-13"></i>
                                                    <input type="text" className="form-control rounded-xs" id="cpf_holder" onChange={HandlerCardInfoChange} value={card.cpf_holder} placeholder="" required={true} />
                                                    <label for="c1d" className="form-label-always-active color-blue-dark">CPF do pagador</label>
                                                </div> 
                                            </div>


                                            <div className={'row '+(pix.pixCopiaECola===''?'hide':'')}>
                                                <center>
                                                    <div onClick={() => _HandleClipBoard(pix.pixCopiaECola, "Código Pix copiado")}>
                                                        <div>Código do PIX Copia e Cola</div>
                                                        <div className="form-control rounded-xs"><div><small style={{fontSize:'11px', wordWrap:'nowrap'}}>{pix.pixCopiaECola}</small></div><br /><br /><b>Toque para copiar para área de transferência</b><br /><br /><br /></div>
                                                        
                                                    </div>
                                                </center>
                                            </div>

                                            <br />

                                            <div className={'row '+(pix.pixCopiaECola===''?'hide':'')} onClick={() => _HandleClipBoard(pix.pixCopiaECola, "Código Pix copiado")}>
                                                <center><img src={pix.urlImagemQrCode} alt="" style={{width:'80%', height:'auto'}}/></center>
                                                <br />
                                            </div>

                                            <button type="submit" className={'btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4 ' + (pix.pixCopiaECola===''?'':'hide')} >GERAR CÓDIGO PIX</button>
                                        </form>
                                    </>)
                                }
                            </div>
                        </div>

                    ) :(<>
                        <button type="button" className={'btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4 ' + (boleto.barcode===''?'':'hide')} onClick={HandleFreeRenew}>Renovar plano gratuitamente</button>
                    </>) }
                </div>
            </div>

        </div>
    )
}