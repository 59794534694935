/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { HubObjects } from '../../scripts/HubObjects';
import './index.css';



export default function MenuTitle({...props}) {
    const {title, backTo} = props;


    const HandleOpenSidebar = () => {
        HubObjects.Exec("TOOGLE_SIDEBAR", false);
    }
    
 
    return (
        <>
            <div className="MenuTitle pt-3">
                <div className="page-title d-flex">
                    <div className="align-self-center">
                        <LinkContainer to={backTo}>
                        <a data-back-button="" className="me-3 ms-0 icon icon-xxs bg-theme rounded-s shadow-m">
                            <i className="bi bi-chevron-left color-theme font-14"></i>
                        </a>
                        </LinkContainer>
                    </div>
                    <div className="align-self-center me-auto">
                        <h1 className="color-theme mb-0 font-18">{title}</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="#" onClick={HandleOpenSidebar}
                            className="icon icon-xxs gradient-highlight color-white shadow-bg shadow-bg-xs rounded-s">
                            <i className="bi bi-list font-20"></i>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}