/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';

import { Context } from '../../../Context/AuthContext';



export default function GalleryNames() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [planos, setPlanos] = useState([]);

    const loadedRef = useRef(false);



    //#region Effects
    useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadPlanos();
            }
        }
    }, [user]);
    //#endregion Effects



    //#region Loaders
    const LoadPlanos = () => {
        Promise.resolve(new Request().Run("api/Home/Planos", "POST", {guid: user.guid}, user.token, 3600*1000))
        .then((data) => {
            if(data.length > 0) {
                setPlanos(data);
                HubObjects.Set("planos", data);
            }
        }).catch(() => {
            
        });
    }
    //#endregion Loaders


    return (
        <>
            <div className="content mb-0">
                <div className="d-flex">
                    <div className="align-self-center">
                        <h3 className="font-16 mb-2">Planos de Assinatura</h3>
                    </div>
                    <div className="align-self-center ms-auto">
                        <LinkContainer to={'/Planos'} >
                            <a className="font-12 pt-1">Ver todos</a>
                        </LinkContainer>
                    </div>
                </div>
            </div>


            <div className="splide_names">
                <Splide options={ {rewind: false, type: 'loop', gap : '10px', autoplay: false } } >
                    {
                        planos.length > 0 ? (
                            planos.map((item, i) => (
                                <SplideSlide key={'plan'+i}>
                                    <LinkContainer to={'/Planos/' + item.plano_id} >
                                    <a className="card border-0 shadow-card shadow-card-m rounded-m">
                                        <img src={'/images/pictures/' + item.image} alt={item.name} className="card border-0 shadow-card shadow-card-m rounded-m"/>
                                    </a>
                                    </LinkContainer>
                                    <h6 className="pt-2 font-600">Valor - {Currency(item.valor)}</h6>
                                </SplideSlide>
                            ))
                        ) : (<></>)
                    }
                </Splide>
            </div>

          
       
        </>
    )
}