export const Cache = {
    Set: (key, value) => {
        localStorage.setItem(key, value);
    },


    Get: (key) => {
        return localStorage.getItem(key);
    },


    JSet: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },


    JGet: (key) => {
        return JSON.parse(localStorage.getItem(key));
    },
}