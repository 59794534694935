import React, { useContext, useState, useEffect, useRef } from 'react';
import './index.css';


import { Context } from '../../../Context/AuthContext';


export default function Content() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);


    return (
        <div id="page" className="Privacidade">

            <div className="page-content footer-clear card card-style">
                <h2>Privacidade e Segurança</h2>
                <br />
                <p>A CONECTAR TECNOLOGIA NEGÓCIOS E PARTICIPAÇÕES LTDA (“I2PLAY”), pessoa jurídica de direito privado, inscrita no CNPJ 65.452.005/0001-09, com sede na Rua Itaboaté nº 86, bairro Vila Olímpia, Taubaté/SP, CEP. 12.062-717, DECLARA, em atenção aos termos da Lei Geral de Proteção de Dados Pessoais – LGPD – Lei 13.709/18, que está comprometida em proteger a privacidade e os dados pessoais dos seus clientes, fornecedores, parceiros e colaboradores.</p>
                <p>Esta Política de Privacidade tem por objetivo esclarecer as condições para tratamento de dados pessoais coletados e fornecidos em nossos processos, site, e-commerce, redes sociais e demais plataformas de tecnologias que por ventura possam ser adotadas pela I2PLAY.</p>
                <p>Na presente Política de Privacidade você poderá encontrar as seguintes informações:<br />

                1. Quais dados coletamos;<br />
                2. Como coletamos seus dados;<br />
                3. Para quais finalidades utilizamos os seus dados;<br />
                4. Como armazenamos os seus dados;<br />
                5. Quem mais tem acesso aos seus dados;<br />
                6. Quais são os seus direitos com relação à coleta e uso dos seus dados;<br />
                7. Por quanto tempo mantemos os seus dados;<br />
                8. Como entrar em contato conosco; e,<br />
                9. Revisão e atualização.<br />
                </p>
                <p>1. QUAIS DADOS PESSOAIS SÃO COLETADOS PELA REFRICRIL:</p>
                <p>A I2PLAY dispõe de ferramentas de tecnologia para interagir com clientes, fornecedores, parceiros e colaboradores nas quais, com o consentimento do titular dos dados, realiza a coleta das seguintes informações:</p>
                <p>CADASTRO: Novo cadastro: Login. Dados cadastrais: Nome completo; Telefone; CPF/CNPJ; Endereço completo; Site; informações Adicionais; Tipo de Serviço que presta; Horário de atendimento; Endereço de e-mail; e, Senha. Dados para acesso: Endereço de e-mail e Senha.</p>
                <p>SITE: Endereço de IP; Dados de dispositivos móveis; Dados de logs; Cookies/tecnologias de rastreamento; Dados de localização Observações: Dados de localização do usuário podem ser coletados visando a melhor experiência de navegação e ofertas. Em caso de navegação anônima, a geolocalização fica desabilitada não coletando dados do usuário.</p>
                <p>COOKIES: Pode-se usar, em alguns casos o recurso de cookies para coletar, tratar, armazenar e/ou compartilhar bem como reconhecer o usuário em nova navegação a fim de aprimorar a experiência do usuário e apresentar conteúdo direcionado aos seus interesses tais como ofertas, lançamentos de produtos e eventuais solução de dúvidas.</p>
                <p>2. COMO A I2PLAY COLETA OS SEUS DADOS:</p>
                <p>A I2PLAY poderá, ao longo do relacionamento, coletar os seus dados das seguintes formas:</p>
                <p>2.1. Informações fornecidas diretamente por você: Ao realizar um cadastro, uma compra ou participar de uma promoção, você poderá compartilhar os dados que serão utilizados para os fins indicados pela i2play no ato da coleta.</p>
                <p>2.2. Dados obtidos de terceiros e fontes públicas: Visando atender às finalidades específicas e legítimas da I2PLAY, durante o relacionamento, a empresa poderá utilizar fontes de terceiros, como parceiros e fornecedores, bem como de bases públicas para coletar dados. Por exemplo:<br />
                • Bancos, Cooperativas de Crédito e demais instituições financeiras, bem como órgãos de proteção ao crédito, em virtude de eventuais transações não concretizadas e/ou negociações não completadas;<br />
                • Órgãos de defesa do consumidor e portais relacionados às reclamações que, porventura, você tenha realizado;
                </p>
                <p>
                2.3. Dados coletados de forma automática: Ao acessar este site, seu computador, mediante autorização, recebe alguns arquivos de texto chamados “cookies”, que são utilizados pela I2PLAY na coleta e armazenamento automático de dados. No site da I2PLAY, os cookies são utilizados para as seguintes finalidades:<br />
                • Otimização das funcionalidades do site: Os cookies são usados para que o site seja apresentado de maneira mais eficiente e otimizada à sua visualização.<br />
                • Analisar o seu comportamento enquanto usuário do site: Identificação dos links que são pouco acessados, das eventuais dificuldades de navegação e outros problemas enfrentados pelos clientes no site da empresa.<br />
                • Obter informações sobre suas preferências: A I2PLAY utiliza os cookies de terceiros para ter acesso aos dados inerentes as suas preferências de acordo com o seu comportamento no site da empresa, o que abrange as páginas que você acessou e os sites de onde você veio, de modo que possamos entender o impacto de nossas campanhas.<br />
                • Neste ponto, se faz necessário esclarecer que o seu navegador pode ser configurado para recusar o recebimento de cookies e possui funções para removê-los a qualquer momento.
                </p>
                <p>3. PARA QUAIS FINALIDADES A I2PLAY UTILIZA OS SEUS DADOS:</p>
                <p>De forma cristalina, a I2PLAY realiza o tratamento dos seus dados para propósitos legítimos e específicos, cujas finalidades estão elencadas a seguir:</p>
                <p>Prestação de serviços: Os seus dados são essenciais para viabilizar a nossa relação comercial, eis que são necessários para entregar os produtos adquiridos ou serviços contratados;</p>
                <p>Segurança: Visando garantir a sua segurança, a I2PLAY coleta e armazena os seus dados para evitar que fraudes sejam praticadas por terceiros em seu nome;</p>
                <p>Promover atividades: Os seus dados são armazenados para que a I2PLAY possa lhe encaminhar comunicados sobre novidades, promoções, publicidade, eventos, pesquisas, sorteios, newsletters e demais informações comerciais.
                Estudos e estatísticas: Elaborar estatísticas para identificação do perfil do usuário com o objetivo de desenvolvimento de campanhas.
                </p>
                <p>Recrutamento e seleção: Recrutar currículos para processo seletivo.
                Cumprimento de obrigações legais: A I2PLAY poderá realizar o tratamento dos seus dados para atendimento de obrigações dispostas em leis e regulamentos de órgãos governamentais.
                </p>
                <p>4. COMO A I2PLAY ARMAZENA OS SEUS DADOS:</p>
                <p>A I2PLAY dispõe de procedimentos de segurança internos que definem a forma como os seus dados devem ser tratados pela empresa. Referidas normas têm como objetivo garantir o tratamento adequado e seguro dos dados.
                Além do mais, a I2PLAY, preocupada na proteção dos dados de seus clientes e colaboradores, dispõe de uma equipe técnica especializada no assunto, que não mede esforços para manter os dados seguros, protegidos de acessos não autorizados, situações acidentais ou ilícitas, perda, alteração ou qualquer outra forma de tratamento não permitido por lei.
                </p>
                <p>5. QUEM MAIS TEM ACESSO AOS SEUS DADOS:</p>
                <p>Quando necessário para viabilizar a relação comercial entre empresa e cliente, a I2PLAY poderá compartilhar os seus dados com seus parceiros, sempre em estrita observância da Lei Geral de Proteção de Dados Pessoais – LGPD – Lei 13.709/18, como, por exemplo:</p>
                <p>Parceiros Comerciais: Visando apoio às suas atividades sempre respeitando os direitos dos titulares dos dados, dentro dos limites permitidos em Lei Geral de Proteção de Dados Pessoais - LGPD;</p>
                <p>Fornecedoras de bens e serviços: Tais como empresas de marketing, transportadoras, processadores de cartão de pagamento, tecnologia da informação, assessoria jurídica e empresa de cobrança, sendo certo que tais empresas serão submetidas às regras de tratamento de dados equivalentes àqueles adotadas pela I2PLAY;</p>
                <p>Cumprimento de medida judicial: O que será realizado pela I2PLAY mediante o recebimento de ordem judicial ou pelo requerimento de autoridades públicas que detenham competência legal para sua requisição.</p>
                <p>A I2PLAY destaca que em respeito aos preceitos da Lei Geral de Proteção de Dados Pessoais – LGPD – Lei 13.709/18, mantém relacionamento comercial com parceiros que estão de acordo com a referida norma.</p>
                <p>6. QUAIS SÃO OS SEUS DIREITOS COM RELAÇÃO À COLETA E USO DOS SEUS DADOS:</p>
                <p>Você tem direito a obter da I2PLAY, em relação aos dados tratados pela empresa, a qualquer momento e mediante requisição:</p>
                <p>• Confirmação da existência de tratamento;<br />
                • Acesso aos dados;<br />
                • Correção de dados incompletos, inexatos ou desatualizados;<br />
                • Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com na legislação;<br />
                • Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;<br />
                • Eliminação dos dados pessoais tratados com o consentimento do titular;<br />
                • Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;<br />
                • Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e,<br />
                • Revogação do consentimento.
                </p>
                <p>A I2PLAY destaca que os seus direitos podem ser exercidos mediante contato a nossos canais de atendimento abaixo.</p>
                <p>7. POR QUANTO TEMPO MANTEMOS OS SEUS DADOS:</p>
                <p>Os dados tratados pela I2PLAY serão eliminados quando não forem mais necessários para as finalidades para os quais foram coletados, exceto nos casos em que se faz necessária a manutenção mesmo após o encerramento da prestação de serviços, como ocorre nos casos em que os dados são armazenados para cumprimento de normas legais ou para resguardar os direitos da empresa.</p>
                <p>8. COMO ENTRAR EM CONTATO CONOSCO:</p>
                <p>A I2PLAY está à sua disposição para sanar eventuais dúvidas, receber sugestões ou requisições que envolvam o tratamento de dados pessoais.
                Para isso, a I2PLAY solicita que você entre em contato através do endereço de e-mail: lgpd@i2play.com.br
                </p>
                <p>9. REVISÃO E ATUALIZAÇÃO:</p>
                <p>Esta Política de Privacidade poderá sofrer alterações a qualquer momento, estando sua versão mais atualiza sempre disponível para sua consulta.</p>


                <ul>
                    <li><i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp; <a href="#">Relatório de impacto à proteção de dados</a></li>

                </ul>
            </div>
        </div>
    )
}