import React, { useContext, useState, useEffect, useRef } from 'react';
import './index.css';


import { Context } from '../../../Context/AuthContext';


export default function Content() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);


    return (
        <div id="page" className="Como">

            <div className="page-content footer-clear card card-style">
                <h2>Como funciona o i2play</h2>
                <br />
                <p>Você se cadastra gratuitamente e durante 30 dias poderá receber orçamentos diretamente no seu celular via aplicativo i2play.</p>
                <p>Você poderá responder aos orçamentos recebidos diretamente ao cliente sem intermediários.</p>
                <p>Após 30 dias você continuará recebendo orçamentos, mas terá que optar por um dos nossos planos para ter acesso e responde-los. Acesse agora os nosso planos e veja que é acessível, fácil e seu acesso é liberado instantaneamente e automaticamente, após confirmação do seu pagamento</p>
                <p>Dica: Quanto mais divulgar a sua página pessoal (Link personalizado) i2play, mais chances você terá de receber solicitações de orçamento, divulgue em suas redes sociais, sites, blog, anúncios etc...</p>
                <p>Rapidez em responder faz toda a diferença, pois lembre-se que outras empresas também receberão o mesmo orçamento, por isso é importante você ficar atento as notificações do app i2play e responder aos orçamentos com brevidade para que seu orçamento seja recebido no whatsapp do seu futuro cliente.</p>
                <p>Quer se destacar em sua cidade, entre em contato com nosso departamento de marketing para potencializarmos o número de orçamentos que estará recebendo. Veja agora com nosso time através dos canais:  i2play@gtnp.com.br  ou whatsApp  12 98297-1638</p>
                <p>Você também pode solicitar material de divulgação para divulgar em suas redes sociais, sites, blog, anúncios e colocar seu negócio em evidência!</p>
            </div>
        </div>
    )
}