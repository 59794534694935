/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import moment from 'moment';
import './index.css';


import { Context } from '../../../Context/AuthContext';




export default function Lista() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [planos, setPlanos] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);

    const loadedRef = useRef(false);


     //#region Effects
     useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadPlanos();
            }
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


     //#region Loaders
     const LoadPlanos = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Planos/MeusPlanos", "POST", {guid: user.guid}, user.token, 24*3600*1000))
        .then((data) => {
            if(data.length > 0) {
                setPlanos(data);
                setPageLoading(false);
            }
        }).catch(() => {
            setPageLoading(false);
        });
    }
    //#endregion Loaders


    //#region Functions

    //#endregion Functions


    return (
        <div className="PlanosLista">
            <h6 className="font-700 opacity-50 pb-2"> *Todas as assinaturas são cumulativas</h6>
            <div className="accordion accordion-m border-0" id="accordion-group-7">
                {
                    planos.length > 0 ? (
                        planos.map((item, i) => (
                            <div key={'plan'+i} className={'accordion-item border border-fade-highlight border-bottom-0 ' + (planos.length===1?'rounded-m':(i===0?' rounded-top rounded-m':(i===(planos.length-1)?' rounded-bottom rounded-m':' border-bottom-0')))}>
                                <button id={'accordionB-'+item.plano_id} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#accordionA-'+item.plano_id}>
                                    <i className="bi bi-star-fill color-yellow-dark pe-3 font-14"></i>
                                    <span className="font-600 font-13">{item.name}</span>
                                    <span className="font-300 font-13" style={{color:'red'}}>&nbsp; {Currency(item.valor)}</span>
                                    <i className={'bi ' + (i===(planos.length-1)?'bi-arrow-down-short':'bi-plus') +' font-20'}></i>
                                </button>
                                <div id={'accordionA-'+item.plano_id} className="accordion-collapse collapse" data-bs-parent="#accordion-group-7">
                                    <div className="row px-3 mb-0 pb-3 pt-1">
                                        <div className="col-4"><img src={'/images/pictures/'+item.image} className=" rounded-xs" alt=""/></div>
                                        <div className="col-8">{item.obs}<br />
                                            <b>Vencimento: {moment(item.validade).format('DD/MM/YYYY')}</b>
                                        </div>

                                        <LinkContainer to={'/Pagamento/'+item.plano_id}>
                                            <a className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4">PRORROGAR ASSINATURA</a>
                                        </LinkContainer>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (<></>) 
                }

            </div>
        </div>
    )

}