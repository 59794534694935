import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LefkMMhAAAAACT-rodYz5FSjGdAJC7y2tY0TNO4"
        language="pt-BR"

      >
      <App />
    </GoogleReCaptchaProvider>
  </>
);

reportWebVitals();
