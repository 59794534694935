/* eslint-disable jsx-a11y/anchor-is-valid */
import userEvent from '@testing-library/user-event';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { HubObjects } from '../../../scripts/HubObjects';
import { Request } from '../../../scripts/Request';
import './index.css';

import { Context } from '../../../Context/AuthContext';

export default function ActivityNotify() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [notifications, setNotifications] = useState([]);

    const loadedRef = useRef(false);


    
    //#region Effects
    useEffect(() => {
        if(user.token !== "") {
            if(!loadedRef.current) {
                loadedRef.current = true;
                LoadNotifications();
            }
        }
    }, [user]);
    //#endregion Effects


    //#region Loaders
    const LoadNotifications = () => {
        Promise.resolve(new Request().Run("api/Home/Notifications", "POST", {guid: user.guid}, user.token, 3600*1000))
        .then((data) => {
            if(data.toString() !== "empty") {
                let wait_count = setInterval(()=> {
                    try {
                        let r = HubObjects.Exec("SET_NOTIFICATIONS_COUNT", data.length);

                        if(r === "loaded") {
                            clearInterval(wait_count);
                            setNotifications(data.splice(0,5));
                        } 
                    } catch(e) {
                        
                    }
                }, 100);
            }

        }).catch(() => {
            
        });
   
    }
    //#endregion Loaders


    //#region Functions
    const Priority = (priority) => {
        let arr = ["red", "yellow", "green"];
        return arr[priority];
    }
    //#endregion Functions

    return (
        <>
             <div className="content my-0 mt-n2 px-1">
                <div className="d-flex">
                    <div id="notificacoes" className="align-self-center">
                        <h3 className="font-16 mb-2">Notificações Recentes</h3>
                    </div>
                    {/*<div className="align-self-center ms-auto">
                        <a className="font-12 pt-1">Ver Todas</a>
                    </div>*/}
                </div>
            </div>

            {
                notifications.length > 0 ? (
                    notifications.map((item, i) => (
                    <div key={'notf'+i} className={'card card-style gradient-' + Priority(item.priority) + ' shadow-bg shadow-bg-s'}>
                        <div className="content">
                            <a className="d-flex">
                                <div className="align-self-center">
                                    <h1 className="mb-0 font-40"><i className="bi bi-check-circle color-white pe-3"></i></h1>
                                </div>
                                <div className="align-self-center">
                                    <h5 className="color-white font-700 mb-0 mt-0 pt-1">
                                        {item.title} <br /> {item.body}
                                    </h5>
                                </div>
                                <div className="align-self-center ms-auto">
                                    <i className="bi bi-arrow-right-short color-white d-block pt-1 font-20 opacity-50"></i>
                                </div>
                            </a>
                        </div>
                    </div>))
                ) : (<>
                     <div className="card card-style gradient-gray shadow-bg shadow-bg-s">
                        <div className="content">
                            <a className="d-flex">
                                <div className="align-self-center">
                                    <h1 className="mb-0 font-40"><i className="bi bi-check-circle color-white pe-3"></i></h1>
                                </div>
                                <div className="align-self-center">
                                    <h5 className="color-white font-700 mb-0 mt-0 pt-1">
                                        Nenhuma notificação recente
                                    </h5>
                                </div>
                                <div className="align-self-center ms-auto">
                                    <i className="bi bi-arrow-right-short color-white d-block pt-1 font-20 opacity-50"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </>)
            }


            {/*
            <div className="card card-style gradient-green shadow-bg shadow-bg-s">
                <div className="content">
                    <a href="page-activity.html" className="d-flex">
                        <div className="align-self-center">
                            <h1 className="mb-0 font-40"><i className="bi bi-check-circle color-white pe-3"></i></h1>
                        </div>
                        <div className="align-self-center">
                            <h5 className="color-white font-700 mb-0 mt-0 pt-1">
                                Serviço de Manutenção <br /> Geladeira
                            </h5>
                        </div>
                        <div className="align-self-center ms-auto">
                            <i className="bi bi-arrow-right-short color-white d-block pt-1 font-20 opacity-50"></i>
                        </div>
                    </a>
                </div>
            </div>

            <div className="card card-style gradient-blue shadow-bg shadow-bg-s">
                <div className="content">
                    <a href="page-activity.html" className="d-flex">
                        <div className="align-self-center">
                            <h1 className="mb-0 font-40"><i className="bi bi-check-circle color-white pe-3"></i></h1>
                        </div>
                        <div className="align-self-center">
                            <h5 className="color-white font-700 mb-0 mt-0 pt-1">
                                Serviço de Instalação <br /> Geladeira
                            </h5>
                        </div>
                        <div className="align-self-center ms-auto">
                            <i className="bi bi-arrow-right-short color-white d-block pt-1 font-20 opacity-50"></i>
                        </div>
                    </a>
                </div>
            </div>

            <div className="card card-style gradient-red shadow-bg shadow-bg-s">
                <div className="content">
                    <a href="page-activity.html" className="d-flex">
                        <div className="align-self-center">
                            <h1 className="mb-0 font-40"><i className="bi bi-check-circle color-white pe-3"></i></h1>
                        </div>
                        <div className="align-self-center">
                            <h5 className="color-white font-700 mb-0 mt-0 pt-1">
                                Promoção Relampago<br /> Assine hoje e ganhe 30 dias gratis
                            </h5>
                        </div>
                        <div className="align-self-center ms-auto">
                            <i className="bi bi-arrow-right-short color-white d-block pt-1 font-20 opacity-50"></i>
                        </div>
                    </a>
                </div>
            </div>
            */}

        </>
    )
}