/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Request } from '../../../scripts/Request';
import ReactGA from 'react-ga';
import { FormGenerator } from '../../Form/FormGenerator';
import { Uuid, RemoveAcentos, Currency, IsCPF } from '../../../scripts/StringUtils';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import './index.css';

import { Context } from '../../../Context/AuthContext';


export default function Cadastro() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [saveable, setSaveable] = useState(true);
    const [lojaId, setLojaId] = useState("");
    const [input, setInput] = useState({});
    const [horariosCheck, setHorariosCheck] = useState({
        seg: false,
        ter: false,
        qua: false,
        qui: false,
        sex: false,
        sab: false,
        dom: false
    });

    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _latlngRef = useRef({latitude: "", longitude:""});
    const _userRef = useRef(user);

    const navigate = useNavigate();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    useEffect(() => {
        _userRef.current = user;
    }, [user]);



    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
     //#endregion Effects


      //#region Form Handlers
      const endereco = async (value) => codigo_cidade(value);


      const codigo_cidade = async (value) => {
         CalcLatLng();
      }


      const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], "api/Cadastro/Cidades?uf_codigo=" + value, "GET", { }, "");
        setPageLoading(false);
    }


    const pessoa = async(value) => {
        await _formUserRef.current.Value("cpf", "");
        if(value === "0"){
            await _formUserRef.current.SetObject("cpf", {title: "CPF", mask: "999.999.999-99"});
        } else if(value === "1"){
            await _formUserRef.current.SetObject("cpf", {title: "CNPJ", mask: "99.999.999/9999-99"});
        }
    }


    const blur_email = async(args) => {
        let _cpf = await _formUserRef.current.GetValue("cpf");
        let _email = await _formUserRef.current.GetValue("email");

        if(_cpf !== "" && _email !== "") {
            Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", {cpf: _cpf, email: _email}, "", 0))
                .then((data) => {
                    if(data.toString() === "exists") {
                        setSaveable(false);
                        window.swal("Alerta", "Usuário já existente", "warning");
                        _formUserRef.current.Value("cpf", "");
                        _formUserRef.current.Value("email", "");
                    } else {
                        setSaveable(true);
                    }
                }).catch(() => {
                    
                });
        }
    }


    const blur_cpf = async(args) => {
        let _pessoa = await _formUserRef.current.GetValue("pessoa");
        if(!IsCPF(args) && _pessoa.id === 0){
            window.swal("Alerta", "CPF incorreto!", "warning");
        } else {
            let _cpf = await _formUserRef.current.GetValue("cpf");
            let _email = await _formUserRef.current.GetValue("email");

            if(_cpf !== "" && _email !== "") {
                Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", {cpf: _cpf, email: _email}, "", 0))
                    .then((data) => {
                        if(data.toString() === "exists") {
                            setSaveable(false);
                            window.swal("Alerta", "Usuário já existente", "warning");
                            _formUserRef.current.Value("cpf", "");
                             _formUserRef.current.Value("email", "");
                        } else {
                            setSaveable(true);
                        }
                    }).catch(() => {
                        
                    });
            }
        }
    }


    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formUserRef.current !== undefined) {
                    clearInterval(wait_token);
                        await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                        
                        let user_data = await new Request().Run("api/Cadastro/Load?guid=" + user.guid, "POST", {}, _userRef.current.token);
                        setLojaId(user_data.loja_id);
                        await _formUserRef.current.Load(user_data);
                        await _formUserRef.current.Value("uf_codigo", user_data.uf_codigo);
                        await _formUserRef.current.Select("cidade_codigo", [], "api/Cadastro/Cidades?uf_codigo="+user_data.uf_codigo, "GET", { }, "");
                        await _formUserRef.current.Value("cidade_codigo", user_data.cidade_codigo);
                        CalcLatLng();

                        let _horariosCheck = Object.assign({}, horariosCheck);
                      
                        user_data.horarios.map((item, i) => {
                            if(item.weekday===0) {
                                _horariosCheck.seg = true;
                                window.$("#seg_ini").val(item.ini);
                                window.$("#seg_fim").val(item.fim);
                            } else if(item.weekday===1) {
                                _horariosCheck.ter = true;
                                window.$("#ter_ini").val(item.ini);
                                window.$("#ter_fim").val(item.fim);
                            } else if(item.weekday===2) {
                                _horariosCheck.qua = true;
                                window.$("#qua_ini").val(item.ini);
                                window.$("#qua_fim").val(item.fim);
                            } else if(item.weekday===3) {
                                _horariosCheck.qui = true;
                                window.$("#qui_ini").val(item.ini);
                                window.$("#qui_fim").val(item.fim);
                            } else if(item.weekday===4) {
                                _horariosCheck.sex = true;
                                window.$("#sex_ini").val(item.ini);
                                window.$("#sex_fim").val(item.fim);
                            } else if(item.weekday===5) {
                                _horariosCheck.sab = true;
                                window.$("#sab_ini").val(item.ini);
                                window.$("#sab_fim").val(item.fim);
                            } else if(item.weekday===6) {
                                _horariosCheck.dom = true;
                                window.$("#dom_ini").val(item.ini);
                                window.$("#dom_fim").val(item.fim);
                            }
                        });
                        setHorariosCheck(_horariosCheck);

                        for(let i=1; i<10; i++) {
                            window.$('input[name=q'+i+'][value=' + user_data.info["q"+i] +  ']').attr('checked', true);
                        }
                        window.$("#q10").val(user_data.info["q10"]);

                        user_data.servicos.map((item, i) => {
                            window.$('#s'+item.service_id).attr('checked', true);
                        });

                } else {
                    console.log(1)
                }
            }, 100);
        } catch (e) {
            console.log(e);
            setPageLoading(false);
        }

        /*try {
            setTimeout(async() => {
                await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Select("cidade_codigo", [], "api/Cadastro/Cidades?uf_codigo=26", "GET", { }, "");
                let arr = _formUserRef.current.GetOptions("cidade_codigo");
                _formUserRef.current.Value("cidade_codigo", arr[0].id);
            
                CalcLatLng();
            }, 100);
        } catch(e) { }*/
        setPageLoading(false);
    }


    const _HandleHorarioCheck = (event) => {
        let _hor = Object.assign({}, horariosCheck);
        _hor[event.target.id] = event.target.checked;
        setHorariosCheck(_hor);
    }


    const _HandleHorarioChange = (e) => {
        setInput({...input, [e.currentTarget.id]: e.currentTarget.value});
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpf");
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }
  
        let data = {};
        if(_latlngRef.current) data = Object.assign(data, _latlngRef.current);

        for(let i=1; i<10; i++){
            let qvalue = window.$('input[name=q'+i+']:checked').val();
            if(qvalue) data = Object.assign(data, {["q"+i]: qvalue});
        }

        data = Object.assign(data, {["q10"]: window.$('select[name=q10]').val()});

        let services = [];
        for(let i=1; i<=25; i++){
            let svalue = window.$('#s'+i).is(':checked');
            if(svalue) services.push(window.$('#s'+i).val());
        }
        if(services.length > 0) data = Object.assign(data, {services: services});

        let dias = ["seg", "ter","qua", "qui", "sex", "sab", "dom"];
        let horarios = [];
        let hor = Object.assign({}, horariosCheck);
        dias.map((item, i) => {
            if(hor[item]) {
                horarios.push({weekday: i, ini: window.$('#'+item + '_ini').val(), fim: window.$('#'+item + '_fim').val()});
            }
        })
        data = Object.assign(data, {horarios: horarios, loja_id: lojaId, guid: user.guid});

        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Cadastro/Save", data, '');
        console.log(_result);
        setPageLoading(false);

        if(_result.toString() === "exists") {
            window.swal("Alerta", "Usuário já existente", "warning");
        } else if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Cadastro Atualizado", "success");
            window.$('html, body').animate({ scrollTop: 0 }, 'fast');
            navigate('/Home');
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region geo
    const CalcLatLng = () => {
        let cidade = window.$("#cidade_codigo option:selected").text() || "";
        let uf_codigo =  window.$("#uf_codigo option:selected").val() || "";
        let ufObject = _formUserRef.current.GetObject("uf_codigo");
        let uf = ufObject.type.options.find(u => parseInt(u.id) === parseInt(uf_codigo)).sigla || "";
        let endereco = _formUserRef.current.GetValue("endereco") || "";
        let numero = _formUserRef.current.GetValue("numero") || "";
        let bairro = _formUserRef.current.GetValue("bairro") || "";

        if(cidade !== "" && uf !== "")
        {
            let address = cidade + "-" + uf;
            if(endereco !== "") address = endereco + ", " + cidade + "-" + uf;
            if(endereco !== "" && numero !== "") address = endereco + " " + numero + ", " + cidade + "-" + uf;
            if(endereco !== "" && numero !== "" && bairro !== "") address = endereco + " " + numero + ", " + bairro + ", " + cidade + "-" + uf;

            let geocoder = new window.google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat();
                    let longitude = results[0].geometry.location.lng();
                   _latlngRef.current = {latitude: latitude.toString(), longitude: longitude.toString()};
                } 
            });
        }
        
    }
    //#endregion geo


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            F("email", "fmantipuff@gmail.com");
            F("senha", "1234567");
            F("pessoa", "0");
            F("cpf", "31575780810");
            F("nome", "Felipe Martins");
            F("telefone", "(12) 9111-1111");
            F("whatsapp", "(12) 9111-1111");
            F("uf_codigo", "26");
            F("cidade_codigo", "9726");
            F("endereco", "Rua Cap Antonio Raponso Barreto");
            F("numero", "207");
            F("bairro", "Jd Bela Bista");
            F("cep", "12091-530");
            F("complemento", "Residencia");
            F("site", "https://lifequery.org");
            F("descricao", "Esse é apenas um teste");
            R("q1", 1);
            R("q2", 0);
            R("q3", 1);
            R("q4", 1);
            R("q5", 1);
            R("q6", 1);
            R("q7", 1);
            R("q8", 1);
            R("q9", 1);
            D("q10", 5);
            C("s1", true);
            C("s2", true);
            C("s3", true);
            C("s6", true);
            C("s9", true);
            C("s11", true);
            C("s15", true);
            CalcLatLng();
        }
    });*/


    const F =  async(key, val) => {
        await _formUserRef.current.Value(key, val);
    }

    const R = (key, val) =>{
        window.$('input:radio[name="'+key+'"]').filter('[value="' + val + '"]').attr('checked', true);
    }

    const D =  async(key, val) => {
        await window.$("#"+key).val(val);
    }

    const C =  async(key, val) => {
        await window.$("#"+key).attr('checked', val);
    }
    //#endregion Tests




    return (
        <>
        <div id="page" className="Perfil">

            <div className="page-content footer-clear card card-style">
                <br />
                <form name="fuser" onSubmit={(event) => _HandleSubmit('rcadastro', event)} autoComplete="off" id="form-contact" className="" noValidate>
                    <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/cadastro.json'} execRef={Exec}></FormGenerator>
                    <div style={{clear:'both'}}></div>
                    <br /><br />

                    <div className={'ibox-content ibox-heading endereco_bar'} style={{ padding: '10px', fontSize: '14px' }}>
                        <small><i className="fa fa-stack-exchange"></i> Horários de atendimento</small>
                    </div>

                    <div className="horarios_info">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <input id="seg" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.seg}/> &nbsp;Segunda-feira
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="seg_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.seg} />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="seg_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.seg} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="ter" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.ter}/> &nbsp;Terça-feira
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="ter_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.ter} />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="ter_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.ter} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="qua" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.qua}/> &nbsp;Quarta-feira
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="qua_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qua} />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="qua_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qua} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="qui" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.qui}/> &nbsp;Quinta-feira
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="qui_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qui} />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="qui_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qui} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="sex" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.sex}/> &nbsp;Sexta-feira
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="sex_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sex} />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="sex_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sex} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="sab" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.sab}/> &nbsp;Sabado
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="sab_ini" defaultValue="09:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sab}  />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="sab_fim" defaultValue="13:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sab} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="dom" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.dom}/> &nbsp;Domingo
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="dom_ini" defaultValue="09:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.dom} />
                                    </td>
                                    <td>
                                        <input type="time" className="form-control" id="dom_fim" defaultValue="13:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.dom} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={'ibox-content ibox-heading endereco_bar'} style={{ padding: '10px', fontSize: '14px' }}>
                        <small><i className="fa fa-stack-exchange"></i> Informações adicionais</small>
                    </div>

                    <div className="servicos_info">
                        <hr className="hr"/>
                        <div>
                            <b>Você é assistência técnica autorizada?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q1" value="1" /> Sim</label>
                            <label><input type="radio" name="q1" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você é revenda autorizada de aparelhos de ar condicionado?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q2" value="1" /> Sim</label>
                            <label><input type="radio" name="q2" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você é instalador autorizado de ar condicionado?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q3" value="1" /> Sim</label>
                            <label><input type="radio" name="q3" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você possui loja fixa?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q4" value="1" /> Sim</label>
                            <label><input type="radio" name="q4" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você emite nota fiscal serviço?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q5" value="1" /> Sim</label>
                            <label><input type="radio" name="q5" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você possui curso técnico em refrigeração?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q6" value="1" /> Sim</label>
                            <label><input type="radio" name="q6" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você presta serviço de limpeza e higienização?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q7" value="1" /> Sim</label>
                            <label><input type="radio" name="q7" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você no serviço de limpeza e higienização remove o equipamento?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q8" value="1" /> Sim</label>
                            <label><input type="radio" name="q8" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Você emite documentação PMOC?</b>
                        </div>
                        <div>
                            <label><input type="radio" name="q9" value="1" /> Sim</label>
                            <label><input type="radio" name="q9" value="0" /> Não</label>
                        </div>
                        <hr className="hr"/>
                        <div>
                            <b>Tempo de atuação no mercado</b>
                        </div>
                        <div>
                            <select id="q10" name="q10" className="form-control">
                                <option value="-1">Menos de 1 ano</option>
                                <option value="1">Mais de 1 ano</option>
                                <option value="2">Mais de 2 anos</option>
                                <option value="3">Mais de 3 anos</option>
                                <option value="4">Mais de 4 anos</option>
                                <option value="5">Mais de 5 anos</option>
                                <option value="10">Mais de 10 anos</option>
                                <option value="20">Mais de 20 anos</option>
                            </select>
                        </div>

                    </div>
                    <br />
                    <div className={'ibox-content ibox-heading endereco_bar'} style={{ padding: '10px', fontSize: '14px' }}>
                        <small><i className="fa fa-stack-exchange"></i> Presta serviços em:</small>
                    </div>

                    <div className="servicos_info_eq">
                        <div>
                            <label><input type="checkbox" id="s1" value="1"/> &nbsp;Ar-condicionado</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s2" value="2"/> &nbsp;Camara fria</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s3" value="3"/> &nbsp;Balcão frigorifico</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s4" value="4" /> &nbsp;Bebedouro</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s5" value="5" /> &nbsp;Exaustor</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s6" value="6" /> &nbsp;Geladeira</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s7" value="7" /> &nbsp;Freezer</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s8" value="8" /> &nbsp;Frigobar</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s9" value="9" /> &nbsp;Máquina de lavar</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s10" value="10" /> &nbsp;Secadora de roupa</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s11" value="11" /> &nbsp;Microondas</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s12" value="12" /> &nbsp;Adega</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s13" value="13" /> &nbsp;Máquina de gelo</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s14" value="14" /> &nbsp;Máquina de sorvete</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s15" value="15" /> &nbsp;Cervejeira</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s16" value="16" /> &nbsp;Chopeira</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s17" value="17" /> &nbsp;Iogurteira</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s18" value="18" /> &nbsp;Fritadeira</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s19" value="19" /> &nbsp;Ilha de congelamento</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s20" value="20" /> &nbsp;Ultra congelador</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s21" value="21" /> &nbsp;Ultra caneca</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s22" value="22" /> &nbsp;Refrigerador inox</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s23" value="23" /> &nbsp;Ar de painel</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s24" value="24" /> &nbsp;Sala climatizada</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s25" value="25" /> &nbsp;Estufa</label>
                        </div>
                        <div>
                            <label><input type="checkbox" id="s26" value="26" /> &nbsp;Cortina de Ar</label>
                        </div>
                        <div className="clear"></div>
                        
                    </div>
                    &nbsp;
                    <hr />
                    <div className="login_btn_wrapper register_btn_wrapper login_wrapper">
                        <center>
                        <button type="submit" className="btn btn-primary login_btn" disabled={!saveable}> &nbsp;Salvar Perfil&nbsp; </button>
                        </center>
                    </div>
                </form>




            </div>

        </div>
        </>
    )
}