/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './index.css';

export default function MenuInterno() {

return (
    <div className="content py-2">
        <div className="d-flex text-center">
            <div className="me-auto">
                <LinkContainer to="/Orcamentos">
                    <a className="icon icon-xxl rounded-m bg-theme shadow-m"><i className="font-28 color-green-dark bi bi-receipt-cutoff"></i></a>
                </LinkContainer>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Orçamentos</h6>
            </div>
            <div className="m-auto">
                <LinkContainer to="/Orcamentos/Historico">
                    <a className="icon icon-xxl rounded-m bg-theme shadow-m"><i className="font-28 color-red-dark bi bi-clock-history"></i></a>
                </LinkContainer>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Histórico</h6>
            </div>
            <div data-bs-toggle="offcanvas" data-bs-target="#menu-exchange" className="m-auto">
                <LinkContainer to="/Avaliacoes">
                    <a className="icon icon-xxl rounded-m bg-theme shadow-m"><i className="font-28 color-blue-dark bi bi-stars"></i></a>
                </LinkContainer>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Avaliação</h6>
            </div>
            <div className="ms-auto">
                <LinkContainer to="/Compartilhar">
                    <a className="icon icon-xxl rounded-m bg-theme shadow-m"><i className="font-28 color-brown-dark bi bi-list-check"></i></a>
                </LinkContainer>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Bônus</h6>
            </div>
        </div>
    </div>
    )

}