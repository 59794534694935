import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import './index.css';


import Menu from '../Home/Menu'
import Sidebar from '../Sidebar';
import MenuTitle from '../MenuTitle';
import Content from './Content';


export default function Avaliacoes() {
 
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.getElementById("page").scrollIntoView({behavior: 'smooth'});
    }, []);



    return (
        <>
        <div id="page">
            <div className="page-content footer-clear">
                <MenuTitle title="Avaliações" backTo="/Home" />
                <Content />
            </div>
            <br />
            <Menu />
            <Sidebar />
        </div>
        </>
    )
}