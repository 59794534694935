/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';

export default function Menu() {
    const [sideopen, setSideopen] = useState(false);


    const HandleOpenSidebar = () => {
        HubObjects.Exec("TOOGLE_SIDEBAR", false);
    }


    return (
        <div id="footer-bar" className="footer-bar-1 footer-bar-detached">
            <LinkContainer to="/Compartilhar">
                <a href="#"><i className="bi bi-share-fill"></i><span>Compartilhe</span></a>
            </LinkContainer>
            <LinkContainer to="/Perfil">
                <a href="#"><i className="bi bi-person-lines-fill"></i><span>Perfil</span></a>
            </LinkContainer>
            <LinkContainer to="/Home">
                <a href="#" className="circle-nav-2"><i className="bi bi-house-fill"></i><span>Home</span></a>
            </LinkContainer>
            <LinkContainer to="/Planos">
                <a href="#"><i className="bi bi-vector-pen"></i><span>Assinatura</span></a>
            </LinkContainer>
            <a href="#" onClick={HandleOpenSidebar}><i className="bi bi-three-dots"></i><span>Mais</span></a>
        </div>
    )

}